var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-filter"
  }, [_c('filters-sidebar', {
    attrs: {
      "supplier_list": _vm.suppliers_list,
      "table_name": "products"
    }
  }), _vm.currentCategory.name ? _c('b-button', {
    staticClass: "btn-current-category",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.setMenuVisibleStatus(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentCategory.number) + " " + _vm._s(_vm.currentCategory.name) + " ")]) : _vm._e(), _vm.currentCategory.name ? _c('b-button', {
    staticClass: "btn-current-category-small",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.setMenuVisibleStatus(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentCategory.number) + " ")]) : _vm._e(), _c('div', {
    staticClass: "b-filter__search"
  }, [_c('b-form', {
    staticClass: "form hidden",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handlerSearchStr.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "is-keyup": true
    },
    model: {
      value: _vm.handler_search_str,
      callback: function ($$v) {
        _vm.handler_search_str = $$v;
      },
      expression: "handler_search_str"
    }
  }), _c('b-button', {
    attrs: {
      "type": "submit"
    }
  })], 1), _c('div', {
    staticClass: "b-search__wrapper"
  }, [_c('b-form-input', {
    ref: "search-input",
    staticClass: "large",
    attrs: {
      "type": "text",
      "is-keyup": true,
      "placeholder": "Поиск товаров..."
    },
    on: {
      "input": _vm.setSearchStrs,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.selectAll.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search_str,
      callback: function ($$v) {
        _vm.search_str = $$v;
      },
      expression: "search_str"
    }
  }), _c('b-button', {
    staticClass: "icon btn-scan",
    attrs: {
      "id": "tooltip-btn-scan"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/search_scan.svg",
      "alt": ""
    }
  })]), _vm.search_str.length ? _c('b-button', {
    staticClass: "icon btn-scan",
    staticStyle: {
      "right": "35px",
      "cursor": "pointer !important"
    },
    attrs: {
      "id": "cross-button"
    },
    on: {
      "click": _vm.clearSearchStr
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/icon_cancel.svg",
      "alt": ""
    }
  })]) : _vm._e(), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-btn-scan",
      "triggers": "hover",
      "custom-class": "tooltip-scan"
    }
  }, [_vm._v(" Возможен поиск с помощью считывания штрих-кода сканнером ")]), _vm.search_result ? [!_vm.allSelected ? _c('div', {
    staticClass: "b-search-result-products"
  }, [_vm.product_list.length !== 0 && !_vm.isLoading ? [_c('div', {
    staticClass: "category-res",
    on: {
      "click": _vm.selectAll
    }
  }, [_c('span', [_vm._v(" Все совпадения: "), _c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.search_str))])]), _c('img', {
    attrs: {
      "src": "/img/icons/blue_enter.svg",
      "alt": "enter"
    }
  })]), _vm._l(_vm.product_list, function (item, index) {
    var _item$supplier, _item$supplier2;

    return _c('div', {
      key: index,
      staticClass: "item cursor",
      on: {
        "click": function ($event) {
          return _vm.search_product(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-name"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "item-info"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(" " + _vm._s(item.articul ? item.articul : '-----') + " ")]), _c('span', {
      staticClass: "mr-2"
    }, [_vm._v(" " + _vm._s((_item$supplier = item.supplier) !== null && _item$supplier !== void 0 && _item$supplier.name ? (_item$supplier2 = item.supplier) === null || _item$supplier2 === void 0 ? void 0 : _item$supplier2.name : 'Нет данных') + " ")]), _c('span', {
      staticStyle: {
        "float": "right",
        "margin-right": "0"
      }
    }, [_vm._v(" " + _vm._s(item.retailPrice ? item.retailPrice.toFixed(2) : `0.00`) + " ₽ ")])])]);
  })] : [_vm.isLoading ? _c('div', {
    staticClass: "p-2 center"
  }, [_c('b-spinner', {
    staticClass: "mt-4",
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm.product_list.length === 0 ? _c('div', {
    staticClass: "p-3 center"
  }, [_vm._v(" Нет результата ")]) : [_c('div', {
    staticClass: "error apollo"
  }, [_vm._v("Ошибка при запросе")])]]], 2) : _vm._e()] : _vm._e()], 2)], 1), _c('div', {
    staticClass: "d-flex mr-auto filter-btn-small"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.incoming-sidebar",
      modifiers: {
        "incoming-sidebar": true
      }
    }],
    staticClass: "filters-btn-small-style",
    class: {
      'filters-btn-active': !!_vm.countFilters
    },
    attrs: {
      "variant": "outline"
    }
  }, [_c('div', {
    staticClass: "img"
  }, [_c('filter-icon', {
    attrs: {
      "fill": _vm.countFilters ? '#00A3FF' : 'black'
    }
  })], 1), _vm.countFilters ? _c('div', {
    staticClass: "count-filters"
  }, [_vm._v(" " + _vm._s(_vm.countFilters) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "d-flex mr-auto filter-btn-big"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.incoming-sidebar",
      modifiers: {
        "incoming-sidebar": true
      }
    }],
    staticClass: "filters-btn",
    class: {
      'filters-btn-active': !!_vm.countFilters
    },
    attrs: {
      "variant": "outline"
    }
  }, [_c('div', {
    staticClass: "img"
  }, [_c('filter-icon', {
    attrs: {
      "fill": _vm.countFilters ? '#00A3FF' : 'black'
    }
  })], 1), _vm._v(" Фильтры "), _vm.countFilters ? _c('div', {
    staticClass: "count-filters"
  }, [_vm._v(" " + _vm._s(_vm.countFilters) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "btns",
    staticStyle: {
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('span', {
    attrs: {
      "id": "disabled-button"
    }
  }, [_c('b-button', {
    staticClass: "btn-add",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.add_product
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _c('span', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v("Добавить товар")])])], 1), _c('b-button', {
    staticClass: "btn-add-small",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.add_product
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  })]), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showSortModal
    }
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('global-product-modal', {
    attrs: {
      "product": _vm.new_product,
      "barcode": _vm.search_str
    },
    on: {
      "clearInput": _vm.clearInput
    }
  }), _c('modal-scan', {
    ref: "modal_scan",
    attrs: {
      "barcode": _vm.search_str
    },
    on: {
      "clearInput": _vm.clearInput
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }