<template>
  <b-modal
    id="group-markup-modal"
    centered
    @hide="clearModal"
  >
    <template #modal-header>
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; flex-direction: row; overflow: hidden">
          <h5 class="mr-2">Наценка на группу</h5>
          <span> | </span>
          <h5
            class="ml-2"
            style="text-overflow: ellipsis; flex: 1; line-clamp: 1"
          >
            {{ category.name?.length > 20 ? category.name?.slice(0, 18) + '...' : category.name }}
          </h5>
        </div>
        <div style="display: flex; flex-direction: row">
          <span class="mr-1">Текущая наценка на группу: </span>
          <span> {{ category.markup?.value || 0 }}%</span>
        </div>
      </div>
    </template>
    <div class="markup-info-div">
      <div
        style="display: flex; flex-direction: row; align-items: flex-start"
        class="mb-2"
      >
        <img
          src="/img/icons/info_blue.svg"
          class="mr-2"
        />
        <span style="color: black; font-weight: bold"
          >Наценка не распространится на подгруппы и <br />товары с уже действующей наценкой</span
        >
      </div>
      <div style="display: flex; flex-direction: row; align-items: flex-start">
        <b-checkbox v-model="affect_children" />
        <span style="color: black">Принудительно изменить наценку у всех вложенных подгрупп и товаров</span>
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <div
        style="display: flex; flex-direction: row; align-items: center"
        class="mr-3 w-25"
      >
        <b-form-group class="w-50">
          <label for="markup-value-input">Наценка </label>
          <b-form-input
            id="markup-value-input"
            v-model="new_markup"
          />
        </b-form-group>
        <span
          class="ml-2"
          style="color: black"
          >%</span
        >
      </div>
      <div
        style="display: flex; flex-direction: row; align-items: center"
        class="mr-3"
      >
        <b-form-group>
          <label>Округление </label>
          <i-select v-model="round_to">
            <i-option
              v-for="item in round"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </i-option>
          </i-select>
        </b-form-group>
      </div>
    </div>
    <template #modal-footer="{ hide }">
      <div class="d-flex">
        <b-button
          variant="primary"
          @click="submitPriceUp"
        >
          Применить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hide()"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { CategoryModel } from '@/models/category.model'
  import { mapGetters } from 'vuex'

  export default {
    name: 'GroupMarkupModal',
    props: {
      category: {
        type: CategoryModel,
        default: new CategoryModel()
      }
    },
    data() {
      return {
        round: [
          {
            label: 'До рубля',
            value: 'rubles'
          },
          {
            label: 'До 50 коп.',
            value: 'half'
          },
          {
            label: 'Не округлять',
            value: 'none'
          }
        ],
        round_to: this.category?.markup?.round_to ?? 'none',
        new_markup: this.category?.markup?.value ?? 0,
        affect_children: false
      }
    },
    watch: {
      category() {
        this.new_markup = this.category?.markup?.value
        this.round_to = this.category?.markup?.round_to
      }
    },
    methods: {
      async submitPriceUp() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/setGroupMarkup.graphql'),
          variables: {
            input: {
              category: this.category.id,
              round_to: this.round_to,
              value: this.new_markup / 100 || 0,
              branch: this.currentBranch.id,
              affect_children: this.affect_children
            }
          }
        })
        if (data?.UpdateCategoryMarkup) {
          this.$bvModal.hide('group-markup-modal')
          this.affect_children = false
          this.new_markup = ''
          this.round_to = 'none'
        } else this.$noty.error('Ошибка добавления наценки')
      },
      clearModal() {
        this.affect_children = false
        this.new_markup = ''
        this.round_to = 'none'
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .modal-footer {
      padding: 12px 22px !important;
      border-top: 1px solid #e1e1e1;
    }
  }

  .markup-info-div {
    background: #ddf3ff;
    padding: 16px 18px;
    border-left: 2px solid #99daff;
    border-radius: 4px;
    margin-bottom: 16px;
  }
</style>
