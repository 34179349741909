var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "warning-merge-products-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "size": "sm"
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-content__img-block"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/products/warning.svg",
      "alt": "watning"
    }
  })]), _c('div', {
    staticClass: "modal-content__title"
  }, [_vm._v("Действие невозможно")]), _c('div', {
    staticClass: "modal-content__text"
  }, [_vm._v(" " + _vm._s(_vm.warning_text) + " ")]), _c('div', {
    staticClass: "modal-content__buttons-block"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Понятно ")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }