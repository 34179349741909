<template>
  <b-sidebar
    id="incoming-sidebar"
    title="1С Бухгалтерия"
    right
    backdrop
    shadow
  >
    <template #header="{ hide }">
      <p>Фильтры</p>
      <div style="display: flex">
        <b-button
          class="clear"
          variant="outline"
          @click="clearFilters"
        >
          Сбросить
        </b-button>
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>
    <e-select
      v-if="supplier_list?.length && table_name !== 'invoice'"
      v-model="supplier"
      multiple
      search_in_drop
      select_name="supplier"
      :max_selected_count="1"
      :max_len_chose="20"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="supplier_list"
      label="Все поставщики"
      placeholder="Все поставщики"
      @input="updateFilters"
    >
      <template #custom_name="{ item }">
        <div class="entity w-100">
          <div class="name">
            {{ item?.name }}
          </div>
          <div class="inn_kpp">
            <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
            <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
          </div>
        </div>
      </template>
    </e-select>
    <e-select
      v-if="table_name === 'invoice'"
      v-model="senders"
      multiple
      select_name="senders"
      :max_selected_count="1"
      :max_len_chose="20"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="supplier_list"
      label="Все отправители"
      placeholder="Все отправители"
      @input="updateFilters"
    />
    <e-select
      v-if="table_name === 'invoice'"
      v-model="recipients"
      multiple
      select_name="recipients"
      :max_selected_count="1"
      :max_len_chose="20"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="supplier_list"
      label="Все получатели"
      placeholder="Все получатели"
      @input="updateFilters"
    />
    <e-select
      v-if="operations_list?.length && table_name !== 'contracts'"
      v-model="operations"
      multiple
      select_name="operations"
      :max_selected_count="1"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="operations_list"
      label="Все операции"
      key-id="key"
      key-name="label"
      placeholder="Все операции"
      @input="updateFilters"
    >
      <e-option
        v-for="item in operations_list"
        :key="item.key"
        :value="item.key"
        :item="item"
      >
        {{ item.label }}
      </e-option>
    </e-select>
    <e-select
      v-if="storage_list?.length && table_name !== 'payment' && table_name !== 'contracts'"
      v-model="storage"
      multiple
      select_name="storage"
      :max_selected_count="1"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="storage_list"
      label="Все места хранения"
      placeholder="Все места хранения"
      @input="updateFilters"
    />

    <e-select
      v-if="table_name === 'payment'"
      v-model="branches"
      multiple
      select_name="branches"
      :max_selected_count="1"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="branch_list"
      label="Все магазины"
      placeholder="Все магазины"
    />

    <e-select
      v-if="status_list?.length"
      v-model="status"
      multiple
      select_name="status"
      :max_selected_count="1"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="status_list"
      label="Все статусы"
      placeholder="Все статусы"
      @input="updateFilters"
    />
    <e-select
      v-if="table_name === 'invoice'"
      v-model="pay_status"
      multiple
      select_name="pay_status"
      :max_selected_count="1"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="pay_status_list"
      label="Все статусы оплаты"
      placeholder="Все статусы оплаты"
      @input="updateFilters"
    />
    <e-select
      v-if="owners_list?.length && table_name !== 'contracts'"
      v-model="owners"
      multiple
      select_name="owners"
      :max_selected_count="1"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="owners_list"
      label="Все менеджеры"
      :key-name="'first_name'"
      :get_name_chose="(item) => getFullName(item)"
      placeholder="Все менеджеры"
      @input="updateFilters"
    >
      <e-option
        v-for="item in owners_list"
        :key="item.id"
        :value="item.id"
        :item="item"
      >
        {{ getFullName(item) }}
      </e-option>
    </e-select>
    <e-select
      v-if="table_name === 'incoming'"
      v-model="types"
      multiple
      select_name="type"
      :max_selected_count="1"
      :max_selected_count_placeholder="(val) => '+ Еще ' + val"
      :options="types_list"
      label="Все типы"
      placeholder="Все типы"
      @input="updateFilters"
    />
  </b-sidebar>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    name: 'FiltersSidebar',
    props: {
      table_name: {
        type: String,
        default: ''
      },
      pay_status_list: Array,
      operations_list: Array,
      supplier_list: Array,
      storage_list: Array,
      status_list: Array,
      types_list: Array,
      owners_list: Array,
      branch_list: Array
    },
    data() {
      return {
        pay_status: [],
        senders: [],
        branches: [],
        recipients: [],
        supplier: [],
        storage: [],
        operations: [],
        status: [],
        types: [],
        owners: []
      }
    },
    computed: {
      ...mapGetters({
        getPagination: 'settings/getPagination'
      }),
      ...mapState({
        getFilters(state, getters) {
          return getters[`${this.table_name}/getFilters`]
        }
      })
    },
    beforeMount() {
      this.storage = this.getFilters?.storages?.concat()
      this.supplier = this.getFilters?.supplier?.concat()
      this.operations = this.getFilters?.operation?.concat()
      this.status = this.getFilters?.status?.concat()
      this.types = this.getFilters?.types?.concat()
      this.owners = this.getFilters?.owners?.concat()
      this.pay_status = this.getFilters?.pay_status?.concat()
      this.recipients = this.getFilters?.recipients?.concat()
      this.senders = this.getFilters?.senders?.concat()
      this.branches = this.getFilters?.branches?.concat()
    },
    methods: {
      ...mapActions({
        setFilters(dispatch, payload) {
          return dispatch(this.table_name + '/setFilters', payload)
        },
        setPagination: 'settings/set_pagination'
      }),
      getFullName(item) {
        return `${item.first_name ? item.first_name : ''} ${item.second_name ? item.second_name : ''} ${
          item.last_name ? item.last_name : ''
        }`.trim()
      },
      updateFilters() {
        this.setPagination({
          ...this.getPagination,
          [this.table_name]: {
            current_page: 1,
            search: '',
            skip: 0,
            take: 20
          }
        })
        this.setFilters({
          supplier: this.supplier?.concat(),
          storages: this.storage?.concat(),
          operation: this.operations?.concat(),
          status: this.status?.concat(),
          types: this.types?.concat(),
          owners: this.owners?.concat(),
          pay_status: this?.pay_status?.concat(),
          recipients: this?.recipients?.concat(),
          senders: this?.senders?.concat(),
          branches: this?.branches?.concat()
        })
      },
      clearFilters() {
        this.storage = []
        this.supplier = []
        this.operations = []
        this.status = []
        this.types = []
        this.owners = []
        this.pay_status = []
        this.senders = []
        this.recipients = []
        this.branches = []
        this.setFilters({
          supplier: [],
          storages: [],
          operation: [],
          status: [],
          types: [],
          owners: [],
          pay_status: [],
          senders: [],
          recipients: [],
          branches: []
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .b-sidebar-body {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bcbcbc;
      border-radius: 10px;
    }
  }

  ::v-deep .select-block {
    margin-bottom: 20px;

    label {
      margin-bottom: 4px;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  ::v-deep .b-sidebar-backdrop {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)) !important;
  }

  .filters {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .inc-select-picker {
    margin-bottom: 20px;
  }

  ::v-deep .b-sidebar {
    width: 428px;
  }

  ::v-deep .b-sidebar-body {
    padding: 22px 22px;
    background-color: #fff;
  }

  ::v-deep .b-sidebar-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    padding: 12px 12px 12px 22px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    p {
      margin: 0;
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #313131;
    }
  }

  ::v-deep .b-sidebar-footer {
    background-color: #fff;
    padding: 12px 22px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .b-action-buttons {
    button {
      height: 42px;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      &:nth-child(2) {
        margin-left: 12px;
        margin-right: 34px;
      }
    }
  }

  .clear {
    color: var(--text-secondary-gray-400, #888);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-right: 32px;
    border-bottom: 1px solid transparent;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .clear:hover {
    border-bottom: 1px solid var(--text-secondary-gray-400, #888);
  }

  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    p {
      margin-bottom: 0;
    }
    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      //color: #313131;
    }
    .inn_kpp {
      display: flex;
      .inn {
        margin-right: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }
</style>
