<template>
  <b-modal
    id="warning-merge-products-modal"
    centered
    hide-footer
    hide-header
    size="sm"
  >
    <div class="modal-body">
      <div class="modal-content">
        <div class="modal-content__img-block">
          <img
            src="/img/icons/products/warning.svg"
            alt="watning"
          />
        </div>

        <div class="modal-content__title">Действие невозможно</div>

        <div class="modal-content__text">
          {{ warning_text }}
        </div>
        <div class="modal-content__buttons-block">
          <div class="b-action-buttons">
            <b-button
              variant="primary"
              class="cursor"
              @click="close"
            >
              Понятно
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'WarningMergeProductsModal',

    props: {
      warning_text: {
        type: String,
        default: ''
      }
    },

    methods: {
      close() {
        this.$bvModal.hide('warning-merge-products-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .modal-header {
    margin-bottom: 0;
    margin-top: 22px;
  }

  :deep .modal-dialog {
    max-width: 420px;
  }

  :deep .modal-body {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  :deep .modal-content {
    padding-top: 0px !important;
  }

  .modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__img-block {
      margin-top: 22px;
    }

    &__title {
      color: var(--Text-primary---gray700, #313131);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;
    }

    &__text {
      color: var(--Text-primary---gray700, #313131);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__buttons-block {
      margin-top: 18px;
      margin-bottom: 22px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
