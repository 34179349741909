<template>
  <section id="products-table">
    <div
      class="products-wrapper"
      :style="{ height: 'calc(100% - 65px)' }"
    >
      <product-movement-modal :product="selectedProduct" />
      <merge-products-modal
        :products="selected"
        @merge_products="refetchProducts"
      />
      <div
        class="card"
        style="overflow: hidden"
      >
        <table-filter
          @addProduct="addProduct"
          @product_found="setProducts"
          @refresh_data="refetchData"
        />
        <template v-if="currentCategory.id !== undefined">
          <resizable-table
            class="table-products table-products-main"
            :default_fields="table_fields"
            table_name="products_table"
            :inside_card="false"
            :items="products"
            :busy="$apollo.queries.ProductsV2.loading"
            height="100%"
            @row-click="rowClick"
          >
            <template #head_id>
              <e-checkbox
                :checked="select_all"
                @click="changeSelectAll"
              />
            </template>

            <template #body_id="{ item }">
              <div @click.stop>
                <b-form-checkbox
                  :checked="selected?.some((el) => el.id === item?.id)"
                  @change="(val) => addProduct(val, item)"
                />
              </div>
            </template>

            <template #body_retailPrice="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template #body_name="{ item }">
              <div style="display: flex; flex-direction: column; text-align: left">
                {{ item?.name }}
                <div class="product-info-div">
                  <span class="mr-2">{{ item?.articul }}</span
                  ><span class="supplier">
                    {{ item.supplier?.name ?? '-' }}
                  </span>
                </div>
              </div>
            </template>

            <template #body_manufacturer="{ item }">
              {{ item?.manufacturer?.name ?? '-' }}
            </template>

            <template v-for="(storage, index) of storage_list">
              <template :slot="'children_' + storage.id">
                <span
                  :id="storage.id"
                  :key="index"
                  >{{ storage.name }}</span
                >
                <b-tooltip
                  :key="storage.id + index"
                  :target="storage.id"
                >
                  {{ storage.name }}
                </b-tooltip>
              </template>
            </template>

            <template #body_purchasePrice="{ value }"> {{ formatPrice(value) }} ₽ </template>

            <template
              v-for="storage of storage_list"
              #[`body_${storage.id}`]="{ item }"
            >
              {{ getRemainderByStorage(item, storage.id) }}
            </template>

            <template #body_total="{ item }">
              {{ getTotalRemainders(item) }}
            </template>

            <template #body_remainders="{ item }">
              <div
                style="display: flex; flex-direction: row; justify-content: space-around"
                class="center"
              >
                <div
                  style="display: flex; flex: 1; justify-content: center; align-items: center; padding: 0"
                  class="center"
                >
                  {{ getTotalRemainders(item) }}
                </div>
                <template v-if="storage_list.length > 1">
                  <div
                    v-for="(storage, index) in storage_list"
                    :key="index"
                    style="display: flex; flex: 1; justify-content: center; align-items: center; padding: 0"
                    class="center"
                  >
                    {{ getRemainderByStorage(item, storage.id) }}
                  </div>
                </template>
              </div>
            </template>

            <template #body_measurement="{ value }">
              {{ value?.name ?? '-' }}
            </template>

            <template #body_package="{ value }">
              {{ value?.[0]?.barcode?.barcode ?? '--' }}
            </template>

            <template #body_markup="{ value }">
              {{ value ? value + '%' : '--' }}
            </template>

            <template #body_nds="{ item }">
              {{ item.taxRate?.name ?? '--' }}
            </template>

            <template #body_country="{ item }">
              <div class="table-left-text">{{ item.country?.name ?? '--' }}</div>
            </template>

            <template #body_supplier="{ item }">
              <div class="table-left-text">{{ item.supplier?.name ?? '--' }}</div>
            </template>

            <template #body_category="{ item }">
              <div class="table-left-text">{{ item.category?.name ?? '--' }}</div>
            </template>
          </resizable-table>

          <table-navbar
            class="m-3"
            style="position: absolute; bottom: 0; right: 0; left: 0; margin-left: 0 !important"
            :items="selected"
            hide_print
            items_name="product"
            :clear_selected="clearSelected"
            @remove_items="checkIdsToDelete"
            @print_price_tags="printPriceTags"
            @product_move="productMove"
            @show_move="showMovement"
            @revaluate="createRevaluation"
            @copy="copyProduct"
            @merge_products="mergeProducts"
          />
        </template>
        <template v-else>
          <div class="center empty-block">
            Выберите группу товаров, создайте группу товаров или загрузите со своей кассы
          </div>
        </template>
      </div>
      <modal-print-price-tags
        :products="print_products"
        :loading="loading_print"
      />
      <pagination
        style="margin: 0"
        :total="this.getPagination.products.total"
        table_name="products"
        @change_pagination="handlerPagination"
      />
      <modal-select-group
        ref="category-product-move"
        :set_category="setCategory"
        :category="currentCategory"
      />
    </div>
  </section>
</template>

<script>
  import * as R from 'ramda'
  import { mapActions, mapGetters } from 'vuex'
  import TableFilter from './TableFilter.vue'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import Pagination from '@/views/shared/components/table/Pagination'
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags.vue'
  import ModalSelectGroup from '@/views/products/modal/ModalSelectGroup'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal'
  import { ProductModel } from '@/models/product.model'
  import ResizableTable from '@/components/ResizableTable'
  import { create_document_by_type } from '@/utils/create_document_by_type'
  import ECheckbox from '@/components/ECheckbox.vue'
  import MergeProductsModal from './modal/MergeProductsModal.vue'

  export default {
    components: {
      ResizableTable,
      ProductMovementModal,
      TableFilter,
      TableNavbar,
      Pagination,
      ModalPrintPriceTags,
      ModalSelectGroup,
      ECheckbox,
      MergeProductsModal
    },

    apollo: {
      ProductsV2: {
        query: require('../gql/getProducts.gql'),
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        notifyOnNetworkStatusChange: true,
        variables() {
          const pagination = this.getPagination
          const { id, skip, take } = pagination.products
          return {
            category: {
              id: this.currentCategory.id
            },
            branch: this.currentBranch.id,
            pagination: { id, skip, take },
            suppliers: this.getFilters.supplier?.map((el) => el.id)
          }
        },
        result({ data }) {
          if (!data) return
          const skip = data?.ProductsV2?.current_skip ?? data?.ProductsV2?.skip
          this.setPagination({
            ...this.getPagination,
            products: {
              ...this.getPagination.products,
              skip: skip,
              current_page: Math.floor(skip / this.getPagination.products.take) + 1,
              total: data?.ProductsV2?.total
            }
          })
          this.items_total = R.pathOr(0, ['ProductsV2', 'total'], data)
          this.products = R.pathOr([], ['ProductsV2', 'products'], data)
          this.tableIsBusy = false
          this.update_category = false

          this.$nextTick(() => {
            if (this.getPagination.products?.scrollTo) {
              const scroll = document.getElementById(`${this.getPagination.products.scrollTo}`)?.offsetTop
              document.querySelector('.products_table')?.scrollTo({
                top: scroll - 100,
                left: 0,
                behavior: 'smooth'
              })
            }
          })
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        },
        skip() {
          this.currentCategory.id === undefined
          return false
        }
      },
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages
          const fields = JSON.parse(JSON.stringify(this.fields))
          if (this.storage_list.length > 1) {
            const index = fields.findIndex((el) => el.key === 'remainders')
            const storages = [
              {
                key: 'total',
                label: 'Итого',
                checked: true
              },
              ...this.storage_list.map((el) => {
                return {
                  key: el.id,
                  storage: el.id,
                  class: 'text-right',
                  label: el.name,
                  checked: true
                }
              })
            ]
            fields[index].children = storages
            this.table_fields = fields
          } else this.table_fields = fields
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: () => ({
      loading_print: false,
      firstRender: true,
      products: [],
      storage_list: [],
      select_all: false,
      tableIsBusy: true,
      items_total: 0,
      table_fields: [],
      print_products: [],
      selectedProduct: new ProductModel()
    }),

    computed: {
      ...mapGetters({
        getPagination: 'settings/getPagination',
        currentCategory: 'products/getCurrentCategory',
        currentBranch: 'settings/getCurrentBranch',
        selected: 'products/getCurrentProducts',
        getPaginationByName: 'settings/getPaginationByName',
        getFilters: 'products/getFilters'
      }),
      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            checked: true,
            class: 'center p-0',
            thStyle: 'width: 50px',
            width: 50
          },
          {
            key: 'name',
            label: 'Наименование',
            thStyle: 'width: 350px',
            checked: true,
            thClass: 'white-bg',
            sortable: true,
            width: 350
          },
          {
            key: 'retailPrice',
            label: 'Розн. цена',
            class: 'center',
            checked: true,
            thStyle: 'width: 100px',
            thClass: 'white-bg',
            width: 100
          },
          {
            key: 'remainders',
            label: 'Остаток',
            checked: true,
            class: 'text-right',
            thClass: 'white-bg',
            width: 200
          },
          {
            key: 'measurement',
            class: 'center',
            checked: true,
            label: 'Ед. изм.',
            thStyle: 'width: 50px',
            thClass: 'white-bg',
            width: 70
          },
          {
            key: 'purchasePrice',
            label: 'Закупочная цена',
            checked: true,
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg',
            width: 100
          },
          {
            key: 'markup',
            label: 'Наценка',
            checked: true,
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg',
            width: 100
          },
          {
            key: 'nds',
            label: 'НДС',
            checked: true,
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg',
            width: 100
          },
          {
            key: 'package',
            checked: true,
            label: 'Штрих код',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg',
            width: 100
          },
          {
            key: 'manufacturer',
            label: 'Производитель',
            checked: true,
            width: 200
          },
          {
            key: 'country',
            label: 'Страна',
            checked: false,
            width: 200
          },
          {
            key: 'supplier',
            label: 'Поставщик',
            checked: false,
            width: 200
          },
          {
            key: 'category',
            label: 'Группа',
            checked: false,
            width: 200
          }
        ]
      },

      selectedProducts: function () {
        return Array.isArray(this.selected) ? this.selected : []
      }
    },

    watch: {
      products(newProducts) {
        let currentSelectedAfterPagination = newProducts.filter((product) => {
          return this.selected.some((el) => product.id === el.id)
        })
        if (currentSelectedAfterPagination.length === 0 && this.products.length === 0) {
          this.select_all = false
        } else if (currentSelectedAfterPagination.length === this.products.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      }
    },

    mounted() {
      document.body.classList.add('body-page-products')
    },

    destroyed() {
      document.body.classList.remove('body-page-products')
    },

    methods: {
      ...mapActions({
        setCurrentCategory: 'products/setCurrentCategory',
        setCurrentProducts: 'products/setCurrentProducts',
        setPagination: 'settings/set_pagination'
      }),

      refetchProducts() {
        this.refetchData()
        this.setCurrentProducts([])
      },

      mergeProducts() {
        this.$bvModal.show('merge-products-modal')
      },

      changeSelectAll() {
        this.select_all = !this.select_all
        if (!this.select_all) {
          let select = this.selected
          this.products.forEach((obj) => (select = select.filter((item) => obj.id !== item.id)))
          return this.setCurrentProducts(select)
        }

        let arrayCurrenSelected = []
        this.products.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj.id === id)) {
            arrayCurrenSelected.push(item)
          }
        })
        this.setCurrentProducts([...this.selected, ...arrayCurrenSelected])
      },

      copyProduct(product) {
        this.$router.push({
          name: 'products.new',
          params: {
            ...product,
            purchasePrice: 0,
            name: product.name + ' (копия)',
            copyProduct: true
          }
        })
      },
      refetchData() {
        this.$apollo.queries.ProductsV2.refetch()
      },

      addProduct(val, prod) {
        if (val) this.setCurrentProducts([...this.selected, prod])
        else {
          this.setCurrentProducts(this.selected?.filter((el) => el.id !== prod.id))
        }
        let currentSeleted = this.products.filter((product) => {
          return this.selected.some((el) => product.id === el.id)
        })
        if (currentSeleted.length < this.products.length) this.select_all = false
        if (currentSeleted.length === this.products.length) this.select_all = true
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
          // These options are needed to round to whole numbers if that's what you want.
          // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      formatNumber(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      formatRemainderValue(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 3,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      getTotalRemainders(item) {
        let total = 0
        if (item?.productType?.id === '52a4eab0-55c2-49f1-a152-f02cbc61e0b8')
          // УСЛУГА
          return '-'
        if (!item?.remainders) return 0

        item?.remainders?.forEach((i) => {
          total = total + i.value
        })

        return this.formatRemainderValue(total)
      },

      getBarcode(item) {
        const barcode = R.pathOr('–', ['package', 0, 'barcode', 'barcode'], item)
        return barcode
      },

      clearSelected() {
        this.setCurrentProducts([])
        this.select_all = false
      },

      setProducts(val) {
        this.items_total = val.total
        this.products = val.products
      },

      checkIdsToDelete(ids) {
        this.tableIsBusy = true

        const idsToDelete = ids?.map((el) => el.id)

        this.removeItems(idsToDelete)
      },
      getRemainderByStorage(data, storage) {
        if (data?.productType?.id === '52a4eab0-55c2-49f1-a152-f02cbc61e0b8') return '-'
        return this.formatRemainderValue(data?.remainders?.find((el) => storage === el.storage.id)?.value)
      },
      removeItems(ids) {
        this.$apollo
          .mutate({
            mutation: require('../gql/removeProduct.gql'),
            variables: {
              ids: ids
            }
          })
          .then(({ data }) => {
            const status = data?.RemoveProduct?.status ?? false
            this.setCurrentProducts([])

            if (!status) {
              this.$noty.show('Невозможно удалить товар(ы)')
              this.tableIsBusy = false
              return
            }

            this.$apollo.queries.ProductsV2.refetch()
            this.$noty.show('Товары удалены')
          })
          .catch(() => {
            this.$noty.show('При удалении товара что-то пошло не так. Попробуйте еще раз')
          })
          .finally(() => (this.table_is_busy = false))
      },

      async printPriceTags() {
        this.$bvModal.show('modal-print-tags')
        this.loading_print = true
        const { data } = await this.$apollo.query({
          query: require('../../dashboard/gql/ReadPriceTags.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              branch: this.currentBranch.id,
              product: this.selected?.map((el) => el.id)
            }
          }
        })
        this.print_products = data.ReadPriceTags?.map((el) => el.Product)
        this.loading_print = false
      },

      handlerPagination({ current_page = null, take = null }) {
        this.tableIsBusy = true
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['products']: {
              ...this.getPagination?.['products'],
              id: null,
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['products']: {
              ...this.getPagination?.['products'],
              id: null,
              take
            }
          })
        }
        // if (!this.firstRender) {
        //   this.$apollo.queries.Products.refetch()
        // }
        this.firstRender = false
      },

      rowClick({ id, name }) {
        this.$router.push({ name: 'products.edit', params: { name, id } })
      },
      productMove() {
        this.$bvModal.show('modal-select-group')
      },
      setCategory(category) {
        this.$apollo.mutate({
          mutation: require('../gql/UpdateManyProduct.graphql'),
          variables: {
            input: {
              ids: this.selected.map((el) => el.id),
              category: category.id
            }
          }
        })
        this.$bvModal.hide('modal-select-group')
        this.setCurrentProducts([])
        this.$apollo.queries.ProductsV2.refresh()
      },
      showMovement(item) {
        this.selectedProduct = new ProductModel(this.products.find((el) => el.id === item.id))
        this.$bvModal.show('product-movement')
      },
      async createRevaluation(type) {
        const doc = create_document_by_type(
          type,
          this.currentBranch.id,
          this.selected.map((el) => {
            return {
              product: el.id,
              price: el.purchasePrice,
              retailPrice: el.retailPrice,
              delta: 0
            }
          }),
          this.storage_list?.[0].id
        )
        const { data } = await this.$apollo.mutate({
          mutation: doc.mutation,
          variables: doc.variables
        })
        const route = doc.getRoute(data)
        if (route) this.$router.push(route)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    @media print {
      #modal-print-tags {
        display: none !important;
        position: static !important;
      }
      .modal-dialog {
        display: none !important;
        position: static !important;
      }
    }
    #products-table {
      height: 100%;
    }

    table {
      thead {
        tr {
          th {
            padding: 8px !important;
            overflow: hidden;
          }
        }
      }

      tbody {
        tr {
          cursor: pointer;

          th {
            border-bottom: 1px solid #e1e1e1;
            border-top: none;
            padding: 8px !important;
          }

          td {
            border-bottom: 1px solid #e1e1e1;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #e1e1e1;
            padding: 8px !important;
          }
        }
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    .body-page-products .wrapper__inner {
      height: 100%;
      overflow-y: hidden;
      padding: 24px 20px 15px;
    }

    .products__item {
      flex: 1;
      overflow-y: hidden !important;
    }

    .link-product {
      text-decoration: none;
      color: #313131;
      font-weight: 500;

      &:hover {
        color: #0066ff;
      }
    }

    .storage_header {
      white-space: nowrap;
      max-width: 400px;
    }

    .storage_header:last-of-type {
      border-right: 1px solid #e1e1e1 !important;
    }

    .table-products-main table {
      table-layout: auto;
      width: 100%;
    }

    .table-products-main {
      //min-height: 100% !important;
    }
  }

  #products-table {
    height: 100%;

    .products-wrapper {
      height: 100%;
      margin-bottom: 0;

      .product .empty-block {
        border-top: 1px solid #e1e1e1;
        padding-top: 16px;
      }

      & > .card {
        height: 100%;
        overflow: auto;
        padding-bottom: 0;

        .table-products {
          margin-bottom: 0;
        }
      }
    }

    .link-product {
      &:hover {
        color: #0066ff;
      }
    }

    .product-info-div {
      display: flex;
      flex-direction: row;
      width: 90%;
      max-width: 380px;

      & > span {
        color: #888888;
        font-weight: 500;
      }

      & .supplier {
        color: #888888;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 1rem;
      }
    }
  }

  .table-left-text {
    text-align: left;
  }
</style>
