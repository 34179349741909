var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.menuIsVisible ? _c('div', {
    staticClass: "products__menu"
  }, [_c('comparison-modal'), _c('div', {
    staticClass: "products__menu-title d-flex align-items-center"
  }, [!_vm.isSearchCategory ? _c('div', {
    staticClass: "menu-title"
  }, [_vm._v(" Группы товаров ")]) : _vm._e(), _c('transition', {
    attrs: {
      "name": "input-category"
    }
  }, [_vm.isSearchCategory ? _c('div', {
    staticClass: "menu-block-category-search"
  }, [_c('img', {
    staticClass: "menu-block-category-search__img",
    attrs: {
      "src": "/img/icons/products/search_category.svg",
      "alt": "search"
    }
  }), _c('b-form-input', {
    staticClass: "menu-block-category-search__input",
    attrs: {
      "type": "text",
      "placeholder": "Поиск..."
    },
    on: {
      "change": _vm.setSearchCategory
    },
    model: {
      value: _vm.searchCategory,
      callback: function ($$v) {
        _vm.searchCategory = $$v;
      },
      expression: "searchCategory"
    }
  }), _c('img', {
    staticClass: "menu-block-category-search__close-input",
    attrs: {
      "src": "/img/icons/products/close.svg",
      "alt": "clode"
    },
    on: {
      "click": _vm.closeSearchCategory
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "products__menu-title-left-block"
  }, [!_vm.isSearchCategory ? _c('b-button', {
    staticClass: "cursor mr-2",
    on: {
      "click": _vm.showSearchCategory
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/products/search_category.svg",
      "alt": "search"
    }
  })]) : _vm._e(), _c('b-button', {
    staticClass: "cursor menu-btn-show-add-group",
    attrs: {
      "id": "tooltip-add-group"
    },
    on: {
      "click": _vm.showAddGroup
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/products/product_group_plus.svg",
      "alt": "Добавить группу"
    }
  })]), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-add-group",
      "triggers": "hover",
      "delay": {
        show: 500,
        hide: 200
      },
      "boundary": "viewport",
      "placement": "topleft"
    }
  }, [_vm._v(" Добавить группу товаров ")])], 1)], 1), _vm.block_add_group ? [_c('div', {
    staticClass: "p-2"
  }, [_c('add-group', {
    on: {
      "refetchData": _vm.refetchData,
      "closeAddGroup": _vm.closeAddGroup,
      "groupAddedSuccessfully": _vm.groupAddedSuccessfully
    }
  })], 1)] : _vm._e(), _vm.menu_is_loading ? _c('div', {
    staticClass: "products__menu-no-goups center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm._e(), _vm.menu_arr && _vm.menu_arr.length > 0 && !_vm.menu_is_loading ? [!_vm.connectionStatus ? _c('div', {
    staticClass: "no-connection d-flex align-items-center w-100 justify-content-between"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/attention-white.svg",
      "alt": "error"
    }
  }), _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Свяжите группы с базой франшизы")]), _c('e-button', {
    attrs: {
      "size": "m"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('comparison-modal');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/left-arrow.svg",
      "alt": "error"
    }
  })])], 1) : _vm._e(), _c('div', {
    staticClass: "b-search-result-products"
  }, [_c('menu-item', {
    ref: "menu-item",
    attrs: {
      "close_add_group": _vm.closeAddGroup,
      "items": _vm.arr_menu,
      "open_group": _vm.openGroup,
      "update_menu": _vm.groupAddedSuccessfully,
      "modal_remove": _vm.openModalRemove,
      "current_ids": _vm.current_ids,
      "sections": _vm.sections,
      "current_group": _vm.current_group_id
    },
    on: {
      "refetch-category": _vm.refetchData,
      "close_add_form": _vm.closeAddGroup,
      "set-group-markup": _vm.setGroupMarkup,
      "add-section": _vm.addSection
    }
  })], 1)] : _vm._e(), _c('b-button', {
    staticClass: "toggle-sidebar",
    on: {
      "click": _vm.hideProductMenu
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/toggle-menu-arrow.svg",
      "alt": ""
    }
  })]), _c('b-modal', {
    attrs: {
      "id": "modal-remove-group",
      "hide-footer": "",
      "centered": "",
      "title": "Удалить группу"
    }
  }, [_c('div', {
    staticClass: "center"
  }, [_vm._v("Вы действительно хотите удалить группу " + _vm._s(_vm.group.name) + " ?")]), _c('div', {
    staticClass: "modal-footer mt-4"
  }, [_c('b-button', {
    staticClass: "center",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModalRemove
    }
  }, [_vm._v(" Отмена ")]), _c('elm-button', {
    staticClass: "center",
    attrs: {
      "variant": "primary",
      "button_text": "Удалить",
      "loading": _vm.remove_group_is_loading
    },
    on: {
      "click_btn": _vm.removeGroup
    }
  }, [_vm._v(" Удалить ")])], 1)]), _c('group-markup-modal', {
    attrs: {
      "category": _vm.markup_category
    }
  }), _c('section-modal', {
    attrs: {
      "category": _vm.selectedCategory,
      "sections": _vm.sections
    }
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }