<template>
  <div class="b-filter">
    <filters-sidebar
      :supplier_list="suppliers_list"
      table_name="products"
    />
    <b-button
      v-if="currentCategory.name"
      variant="light"
      class="btn-current-category"
      @click="setMenuVisibleStatus(true)"
    >
      {{ currentCategory.number }} {{ currentCategory.name }}
    </b-button>
    <b-button
      v-if="currentCategory.name"
      variant="light"
      class="btn-current-category-small"
      @click="setMenuVisibleStatus(true)"
    >
      {{ currentCategory.number }}
    </b-button>
    <div class="b-filter__search">
      <b-form
        class="form hidden"
        @submit.prevent="handlerSearchStr"
      >
        <b-form-input
          v-model="handler_search_str"
          type="text"
          :is-keyup="true"
        />
        <b-button type="submit" />
      </b-form>
      <div class="b-search__wrapper">
        <b-form-input
          ref="search-input"
          v-model="search_str"
          type="text"
          class="large"
          :is-keyup="true"
          placeholder="Поиск товаров..."
          @input="setSearchStrs"
          @keyup.enter="selectAll"
        />
        <!--<b-button class="icon btn-filter">
          <img src="/img/icons/search_filter.svg" alt="" />
        </b-button> -->
        <b-button
          id="tooltip-btn-scan"
          class="icon btn-scan"
        >
          <img
            src="/img/icons/search_scan.svg"
            alt=""
          />
        </b-button>
        <b-button
          v-if="search_str.length"
          id="cross-button"
          class="icon btn-scan"
          style="right: 35px; cursor: pointer !important"
          @click="clearSearchStr"
        >
          <img
            src="/img/icons/icon_cancel.svg"
            alt=""
          />
        </b-button>
        <b-tooltip
          target="tooltip-btn-scan"
          triggers="hover"
          custom-class="tooltip-scan"
        >
          Возможен поиск с помощью считывания штрих-кода сканнером
        </b-tooltip>

        <template v-if="search_result">
          <div
            v-if="!allSelected"
            class="b-search-result-products"
          >
            <template v-if="product_list.length !== 0 && !isLoading">
              <div
                class="category-res"
                @click="selectAll"
              >
                <span>
                  Все совпадения:
                  <span style="color: black">{{ search_str }}</span>
                </span>
                <img
                  src="/img/icons/blue_enter.svg"
                  alt="enter"
                />
              </div>
              <div
                v-for="(item, index) in product_list"
                :key="index"
                class="item cursor"
                @click="search_product(item)"
              >
                <div class="item-name">{{ item.name }}</div>
                <div class="item-info">
                  <span class="mr-2">
                    {{ item.articul ? item.articul : '-----' }}
                  </span>
                  <span class="mr-2">
                    {{ item.supplier?.name ? item.supplier?.name : 'Нет данных' }}
                  </span>
                  <span style="float: right; margin-right: 0">
                    {{ item.retailPrice ? item.retailPrice.toFixed(2) : `0.00` }}
                    ₽
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-if="isLoading"
                class="p-2 center"
              >
                <b-spinner
                  variant="primary"
                  class="mt-4"
                />
              </div>
              <div
                v-else-if="product_list.length === 0"
                class="p-3 center"
              >
                Нет результата
              </div>
              <template v-else>
                <div class="error apollo">Ошибка при запросе</div>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>

    <div class="d-flex mr-auto filter-btn-small">
      <b-button
        v-b-toggle.incoming-sidebar
        class="filters-btn-small-style"
        :class="{ 'filters-btn-active': !!countFilters }"
        variant="outline"
      >
        <div class="img"><filter-icon :fill="countFilters ? '#00A3FF' : 'black'" /></div>
        <div
          v-if="countFilters"
          class="count-filters"
        >
          {{ countFilters }}
        </div>
      </b-button>
    </div>

    <div class="d-flex mr-auto filter-btn-big">
      <b-button
        v-b-toggle.incoming-sidebar
        class="filters-btn"
        :class="{ 'filters-btn-active': !!countFilters }"
        variant="outline"
      >
        <div class="img"><filter-icon :fill="countFilters ? '#00A3FF' : 'black'" /></div>
        Фильтры
        <div
          v-if="countFilters"
          class="count-filters"
        >
          {{ countFilters }}
        </div>
      </b-button>
    </div>

    <div
      style="display: flex; gap: 16px"
      class="btns"
    >
      <span id="disabled-button">
        <b-button
          class="btn-add"
          variant="primary"
          @click="add_product"
        >
          <img
            src="/img/icons/btn-plus.svg"
            alt=""
            class="mr"
          />
          <span style="color: white">Добавить товар</span>
        </b-button>
      </span>

      <b-button
          class="btn-add-small"
          variant="primary"
          @click="add_product"
        >
          <img
            src="/img/icons/btn-plus.svg"
            alt=""
            class="mr"
          />
        </b-button>


      <b-dropdown
        no-caret
        variant="none"
      >
        <template #button-content>
          <div class="btn-more">
            <img
              src="/img/icons/settings.svg"
              alt=""
            />
          </div>
        </template>
        <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
      </b-dropdown>
    </div>

    <global-product-modal
      :product="new_product"
      :barcode="search_str"
      @clearInput="clearInput"
    />
    <modal-scan
      ref="modal_scan"
      :barcode="search_str"
      @clearInput="clearInput"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ModalScan from '../modal/ModalScan.vue'
  import GlobalProductModal from '@/views/products/modal/GlobalProductModal'
  import FiltersSidebar from '@/components/sidebar/FiltersSidebar.vue'
  import FilterIcon from '../../../../public/img/icons/FilterIcon.vue'

  export default {
    components: { FilterIcon, FiltersSidebar, GlobalProductModal, ModalScan },

    apollo: {
      Entities: {
        query: require('../gql/Entities.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.suppliers_list = data?.Entities
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },
      ProductSearch: {
        query: require('../gql/searchProduct.gql'),
        fetchPolicy: 'no-cache',
        debounce: 300,
        variables() {
          return {
            name: this.search_str,
            branch: this.currentBranch.id,
            supplier: this.getFilters?.supplier?.length
              ? {
                  ids: this.getFilters.supplier.map((el) => el.id),
                  type: 'must'
                }
              : null
            // category: this.currentCategory.id
          }
        },
        result({ data }) {
          this.new_product = {}
          if (!data.ProductSearch?.total && data.ProductSearch?.ext_product && this.search_str.length === 13) {
            this.new_product = data.ProductSearch?.ext_product
          }
          if (this.search_str.length > 1) {
            this.setPlaceholder(this.search_str)
            this.product_list = data?.ProductSearch?.products ?? []
          } else {
            this.setPlaceholder('Поиск товаров по названию, коду или постав...')
          }

          if (this.product_list.length === 1 && this.product_list[0].found_by_barcode) {
            const product = this.product_list[0]
            if (product.category) this.setCurrentCategory(product.category)
            this.goToPage(product)
            // this.setCurrentProducts([...this.getCurrentProducts, product])
            this.setCurrentsIds(product.category.path)
            // return this.$router.push({
            //   name: 'products.edit',
            //   params: { name: prodcut.name, id: prodcut.id }
            // })

            if (data?.ProductSearch.total === 1 || this.product_list[0].found_by_barcode) {
              if (!this.getCurrentProducts.find((obj) => obj.id === data.ProductSearch.products[0].id))
                this.$emit('addProduct', true, data.ProductSearch.products[0])
              this.allSelected = true
            }
            // if (!data?.ProductSearch.total) {
            //   this.resetTimer()
            //   this.modal_scan()
            // }
            // if (!data?.ProductSearch.total) {
            //   this.resetTimer()
            //   this.modal_scan()
            // }
            this.search_str = ''
            this.setSearchStr('')
            this.$refs['search-input'].select()
          }
          this.isLoading = false
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        skip() {
          return this.search_str.length < 2
        }
      }
    },

    data: () => ({
      isLoading: false,
      isModalScanShow: false,
      handler_search_str: '',
      search_str: '',
      product_list: [],
      suppliers_list: [],
      search_result: false,
      time: 0,
      allSelected: false,
      new_product: {}
    }),

    mounted() {
      this.search_str = ''
      this.search_str = this.getSearchStr
      this.setPlaceholder('Поиск товаров...')
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        currentCategory: 'products/getCurrentCategory',
        getPlaceholder: 'products/getPlaceholder',
        getSearchStr: 'products/getSearchStr',
        getPagination: 'settings/getPagination',
        getCurrentProducts: 'products/getCurrentProducts',
        getFilters: 'products/getFilters'
      }),
      countFilters() {
        return this.getFilters.supplier?.length > 0 ? 1 : 0
      }
    },

    watch: {
      search_str(newVal) {
        if (newVal.length > 1) {
          this.isLoading = true
          return (this.search_result = true)
        }

        this.search_result = false
        this.allSelected = false
      }
    },

    created() {
      this.listenerKeyUp()
      // this.$barcodeScanner.init(this.barcodeScan)
    },

    destroyed() {
      window.removeEventListener('keydown', () => console.info('removeListener'))
      // this.$barcodeScanner.destroy()
    },

    methods: {
      ...mapActions({
        setCurrentCategory: 'products/setCurrentCategory',
        setPlaceholder: 'products/setPlaceholder',
        setCurrentCategoryPath: 'products/setCurrentCategoryPath',
        setSearchStr: 'products/setSearchStr',
        setMenuVisibleStatus: 'products/setMenuVisibleStatus',
        setCurrentsIds: 'products/setCurrentIds',
        setCurrentCategoryMenuId: 'products/setCurrentCategoryMenuId',
        setPagination: 'settings/set_pagination',
        setCurrentProducts: 'products/setCurrentProducts'
      }),

      setSearchStrs(val) {
        this.setSearchStr(val)
        this.search_str = val
      },

      add_product() {
        this.$router.push({ name: 'products.new' })
      },

      clearSearchStr() {
        this.allSelected = false
        this.search_str = ''
        this.setSearchStr('')
        this.setPlaceholder('Поиск товаров по названию, коду или постав...')
        this.$emit('refresh_data')
      },

      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      },

      barcodeScan() {},

      import_products() {
        this.$emit('import_products')
      },

      settings() {
        this.$emit('settings')
      },

      change() {
        this.$emit('change')
      },

      remove() {
        this.$emit('remove')
      },

      modal_scan() {
        this.$refs['modal_scan'].showModal()
      },

      async selectAll() {
        if (this.search_str?.length < 2) return
        this.setPlaceholder('Поиск товаров по названию, коду или постав...')
        const { data } = await this.$apollo.query({
          query: require('../gql/searchProduct.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            pagination: {
              skip: 0,
              take: 1000
            },
            name: this.search_str,
            branch: this.currentBranch.id
          }
        })
        if (data.ProductSearch.total === 0) {
          this.$apollo
            .mutate({
              mutation: require('../gql/checkBarcode.gql'),
              variables: {
                barcode: this.search_str
              }
            })
            .then(({ data }) => {
              const statusOnServe = data.CheckBarcode.status

              if (!statusOnServe) {
                this.resetTimer()
                this.$bvModal.show('global-product-modal')
                return
              }
            })
            .catch((e) => {
              console.error('error: ', e)
            })
        }
        if (data?.ProductSearch?.total >= 1) {
          this.$emit('product_found', data.ProductSearch)
        }
        this.allSelected = true
        this.$refs['search-input'].blur()
      },

      async search_product(product) {
        console.log(1)
        if (this.currentCategory?.id !== product?.category?.id) {
          if (!product?.category)
            product.category = {
              id: null,
              name: 'Товары без категории',
              last: true,
              level: 0,
              int_id: 0,
              path: [null]
            }
          this.setCurrentCategory({ ...product.category })
        }
        product.category = {
          ...product.category,
          parent_groups: { path: product.category.path },
          child_groups: product.category.path
        }
        this.setCurrentCategory(product.category)
        this.setCurrentCategoryMenuId(product.category.path?.[0])
        this.setCurrentsIds(product?.category?.path)
        this.setCurrentProducts([...this.getCurrentProducts, product])
        this.setCurrentCategoryPath(product?.category?.path ?? [])
        this.search_str = ''
        this.setSearchStr('')
        this.search_result = false
        this.resetTimer()
        this.goToPage(product)
      },

      goToPage(product) {
        let ord = product.order

        let take = this.getPagination?.products?.take ?? 20

        // let page = 1

        while (ord > take) {
          ord -= take
          // page++
        }
        setTimeout(
          () =>
            this.setPagination({
              ...this.getPagination,
              products: {
                scrollTo: product.id,
                id: product.id,
                take: this.getPagination?.products?.take ?? 20,
                total: ord
              }
            }),
          0
        )
      },

      clearInput() {
        this.search_str = ''
        this.setSearchStr('')
        this.handler_search_str = ''
      },

      counterSeconds() {
        this.time = this.time + 0.1
      },

      resetTimer() {
        this.time = 0
      },

      listenerKeyUp() {
        const regexpRu = /^[?!,.а-яА-ЯёЁ0-9]+$/
        const regexpEn = /^[?!,.a-zA_Z0-9]+$/
        let interval = null

        window.addEventListener('keydown', (item) => {
          if (item.target.localName === 'input' || item.altKey || item.ctrlKey || item.metaKey) return
          const key = item.key
          if (interval) clearInterval(interval)
          if (this.time === 0) interval = setInterval(this.counterSeconds, 100)

          if (item.target.attributes.isKeyup || key === 'Alt') return false
          if (key === 'Escape' || key === 'Delete') {
            this.search_str = ''
            if (this.allSelected) this.$emit('refresh_data')
            this.allSelected = false
          }

          if (key === 'Backspace') {
            this.allSelected = false
            if (!this.search_str.length) this.$emit('refresh_data')
            this.search_str = this.search_str.substring(0, this.search_str.length - 1)
            return
          }

          if (key === 'Enter') {
            if (this.time < 1) {
              this.$apollo
                .mutate({
                  mutation: require('../gql/checkBarcode.gql'),
                  variables: {
                    barcode: this.search_str
                  }
                })
                .then(({ data }) => {
                  const statusOnServe = data?.CheckBarcode?.status

                  if (!statusOnServe) {
                    this.resetTimer()
                    this.modal_scan()
                    return
                  }
                })
                .catch((e) => {
                  console.error('error: ', e)
                })
            }

            this.resetTimer()
            clearInterval(interval)
          }

          if (regexpRu.test(key) || regexpEn.test(key))
            if (typeof item.key === 'string') return (this.search_str = this.search_str + key)

          this.handler_search_str = this.handler_search_str + key
        })
      },

      handlerSearchStr() {}
    }
  }
</script>

<style lang="scss" scoped>
  button.filter-search {
    background: #f9f9f9;
    border-radius: 2px;
    border: none;
    padding: 0 10px;
    margin-left: 16px;

    &:hover,
    &:active,
    &:focus {
      background: #f9f9f9;
    }
  }

  .category-res {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 14px;
    color: #888888;
    cursor: pointer;

    &:hover {
      background: #efefef;
    }
  }

  .form.hidden {
    display: none;
  }

  .b-filter {
    .btns {
      button {
        width: 32px;
        height: 32px;
        margin-bottom: 16px;

        span {
          color: #313131;
        }

        &.btn-add {
          min-width: 150px;
        }

        &.btn-current-category {
          min-width: 150px;
          text-align: center;
        }
      }
    }

    .btn-group {
      margin-right: 16px;
      margin-bottom: 16px;
      width: 32px;
      height: 32px;
    }
  }

  .btn-current-category {
    padding-right: 15px;
    display: block;
    text-align: left;
    width: 208px;
    background: #efefef;
    border-radius: 2px;
    font-size: 14px;
    line-height: 22px;
    color: #313131;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-current-category-small {
    // padding-right: 15px;
    // text-align: left;
    // width: 208px;
    // background: #efefef;
    // border-radius: 2px;
    // font-size: 14px;
    // line-height: 22px;
    // color: #313131;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    display: none;
  }

  .b-search {
    &__wrapper {
      position: relative;
    }

    &-result-products {
      position: absolute;
      z-index: 10;
      top: 45px;
      left: 0;
      width: 435px;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 10px 0;
      max-height: 460px;
      overflow-y: auto;

      .item {
        padding: 8px 14px;

        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #313131;
        }

        &-info {
          font-size: 12px;
          line-height: 24px;
          color: #888888;
          height: 24px;
          overflow: hidden;

          span {
            margin-right: 12px;
          }
        }

        &:hover {
          background: #efefef;
        }
      }
    }
  }

  .filter-btn-big {
    display: block;
  }
  .filter-btn-small {
    display: none !important;
  }
  
  .filters-btn {
    background: #fff;
    margin-right: auto;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    align-items: center;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    &.filters-btn-active {
      background: #ddf3ff;
      border-color: #ddf3ff;
      color: #00a3ff;
    }
    color: #313131;

    .count-filters {
      font-family: 'Gilroy-Medium', sans-serif;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      color: #00a3ff;
      border-radius: 50px;
      background-color: #fff;
    }

    .img {
      margin-right: 12px;
    }
  }

  .filters-btn-small-style {
    background: #fff;
    margin-right: auto;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    align-items: center;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    &.filters-btn-active {
      background: #ddf3ff;
      border-color: #ddf3ff;
      color: #00a3ff;
    }
    color: #313131;

    .count-filters {
      font-family: 'Gilroy-Medium', sans-serif;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      color: #00a3ff;
      border-radius: 50px;
      background-color: #fff;
    }
    
  }

  .btn-add-small {
          display: none !important;
        }

  @media (max-width: 1545px) {
         .btn-current-category-small {
          display: block;
         }

         .btn-current-category {
          display: none;
         }
        }

        @media (max-width: 1390px) {
          .btns {
      width: 215px;
      margin-left: auto;
          }
          .filter-btn-small {
            display: block !important;
            margin: 0 !important;
          }

          .filter-btn-big {
            display: none !important;
          }
        }

        @media (max-width: 1341px) {
          .btns {
      width: 215px;
      margin-left: auto;
          }
          .btn-add-small {
          display: block !important;
          margin-right: 10px;
        }


        .b-filter {
    .btns {
      width: 90px;
      margin-left: auto;
      gap: 0 !important;
      button {
        

        

        &.btn-add {
          display: none !important;
        }


      }
    }

    
  }

        

    }

    @media (max-width: 1087px) {
      .btns {
      
      margin-left: 0 !important;
      }
    }

</style>
