var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "group-markup-modal",
      "centered": ""
    },
    on: {
      "hide": _vm.clearModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        var _vm$category$name, _vm$category$name2, _vm$category$markup;

        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "overflow": "hidden"
          }
        }, [_c('h5', {
          staticClass: "mr-2"
        }, [_vm._v("Наценка на группу")]), _c('span', [_vm._v(" | ")]), _c('h5', {
          staticClass: "ml-2",
          staticStyle: {
            "text-overflow": "ellipsis",
            "flex": "1",
            "line-clamp": "1"
          }
        }, [_vm._v(" " + _vm._s(((_vm$category$name = _vm.category.name) === null || _vm$category$name === void 0 ? void 0 : _vm$category$name.length) > 20 ? ((_vm$category$name2 = _vm.category.name) === null || _vm$category$name2 === void 0 ? void 0 : _vm$category$name2.slice(0, 18)) + '...' : _vm.category.name) + " ")])]), _c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row"
          }
        }, [_c('span', {
          staticClass: "mr-1"
        }, [_vm._v("Текущая наценка на группу: ")]), _c('span', [_vm._v(" " + _vm._s(((_vm$category$markup = _vm.category.markup) === null || _vm$category$markup === void 0 ? void 0 : _vm$category$markup.value) || 0) + "%")])])])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.submitPriceUp
          }
        }, [_vm._v(" Применить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return hide();
            }
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "markup-info-div"
  }, [_c('div', {
    staticClass: "mb-2",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "flex-start"
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/info_blue.svg"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "font-weight": "bold"
    }
  }, [_vm._v("Наценка не распространится на подгруппы и "), _c('br'), _vm._v("товары с уже действующей наценкой")])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "flex-start"
    }
  }, [_c('b-checkbox', {
    model: {
      value: _vm.affect_children,
      callback: function ($$v) {
        _vm.affect_children = $$v;
      },
      expression: "affect_children"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Принудительно изменить наценку у всех вложенных подгрупп и товаров")])], 1)]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('div', {
    staticClass: "mr-3 w-25",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "center"
    }
  }, [_c('b-form-group', {
    staticClass: "w-50"
  }, [_c('label', {
    attrs: {
      "for": "markup-value-input"
    }
  }, [_vm._v("Наценка ")]), _c('b-form-input', {
    attrs: {
      "id": "markup-value-input"
    },
    model: {
      value: _vm.new_markup,
      callback: function ($$v) {
        _vm.new_markup = $$v;
      },
      expression: "new_markup"
    }
  })], 1), _c('span', {
    staticClass: "ml-2",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("%")])], 1), _c('div', {
    staticClass: "mr-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "center"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Округление ")]), _c('i-select', {
    model: {
      value: _vm.round_to,
      callback: function ($$v) {
        _vm.round_to = $$v;
      },
      expression: "round_to"
    }
  }, _vm._l(_vm.round, function (item) {
    return _c('i-option', {
      key: item.value,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }