var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "products-table"
    }
  }, [_c('div', {
    staticClass: "products-wrapper",
    style: {
      height: 'calc(100% - 65px)'
    }
  }, [_c('product-movement-modal', {
    attrs: {
      "product": _vm.selectedProduct
    }
  }), _c('merge-products-modal', {
    attrs: {
      "products": _vm.selected
    },
    on: {
      "merge_products": _vm.refetchProducts
    }
  }), _c('div', {
    staticClass: "card",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('table-filter', {
    on: {
      "addProduct": _vm.addProduct,
      "product_found": _vm.setProducts,
      "refresh_data": _vm.refetchData
    }
  }), _vm.currentCategory.id !== undefined ? [_c('resizable-table', {
    staticClass: "table-products table-products-main",
    attrs: {
      "default_fields": _vm.table_fields,
      "table_name": "products_table",
      "inside_card": false,
      "items": _vm.products,
      "busy": _vm.$apollo.queries.ProductsV2.loading,
      "height": "100%"
    },
    on: {
      "row-click": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var _vm$selected;

        var item = _ref.item;
        return [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el.id === (item === null || item === void 0 ? void 0 : item.id);
            })
          },
          on: {
            "change": function (val) {
              return _vm.addProduct(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_retailPrice",
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, {
      key: "body_name",
      fn: function (_ref3) {
        var _item$supplier$name, _item$supplier;

        var item = _ref3.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column",
            "text-align": "left"
          }
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " "), _c('div', {
          staticClass: "product-info-div"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.articul))]), _c('span', {
          staticClass: "supplier"
        }, [_vm._v(" " + _vm._s((_item$supplier$name = (_item$supplier = item.supplier) === null || _item$supplier === void 0 ? void 0 : _item$supplier.name) !== null && _item$supplier$name !== void 0 ? _item$supplier$name : '-') + " ")])])])];
      }
    }, {
      key: "body_manufacturer",
      fn: function (_ref4) {
        var _item$manufacturer$na, _item$manufacturer;

        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((_item$manufacturer$na = item === null || item === void 0 ? void 0 : (_item$manufacturer = item.manufacturer) === null || _item$manufacturer === void 0 ? void 0 : _item$manufacturer.name) !== null && _item$manufacturer$na !== void 0 ? _item$manufacturer$na : '-') + " ")];
      }
    }, {
      key: "body_purchasePrice",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ₽ ")];
      }
    }, _vm._l(_vm.storage_list, function (storage) {
      return {
        key: `body_${storage.id}`,
        fn: function (_ref6) {
          var item = _ref6.item;
          return [_vm._v(" " + _vm._s(_vm.getRemainderByStorage(item, storage.id)) + " ")];
        }
      };
    }), {
      key: "body_total",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.getTotalRemainders(item)) + " ")];
      }
    }, {
      key: "body_remainders",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "center",
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "justify-content": "space-around"
          }
        }, [_c('div', {
          staticClass: "center",
          staticStyle: {
            "display": "flex",
            "flex": "1",
            "justify-content": "center",
            "align-items": "center",
            "padding": "0"
          }
        }, [_vm._v(" " + _vm._s(_vm.getTotalRemainders(item)) + " ")]), _vm.storage_list.length > 1 ? _vm._l(_vm.storage_list, function (storage, index) {
          return _c('div', {
            key: index,
            staticClass: "center",
            staticStyle: {
              "display": "flex",
              "flex": "1",
              "justify-content": "center",
              "align-items": "center",
              "padding": "0"
            }
          }, [_vm._v(" " + _vm._s(_vm.getRemainderByStorage(item, storage.id)) + " ")]);
        }) : _vm._e()], 2)];
      }
    }, {
      key: "body_measurement",
      fn: function (_ref9) {
        var _value$name;

        var value = _ref9.value;
        return [_vm._v(" " + _vm._s((_value$name = value === null || value === void 0 ? void 0 : value.name) !== null && _value$name !== void 0 ? _value$name : '-') + " ")];
      }
    }, {
      key: "body_package",
      fn: function (_ref10) {
        var _value$0$barcode$barc, _value$, _value$$barcode;

        var value = _ref10.value;
        return [_vm._v(" " + _vm._s((_value$0$barcode$barc = value === null || value === void 0 ? void 0 : (_value$ = value[0]) === null || _value$ === void 0 ? void 0 : (_value$$barcode = _value$.barcode) === null || _value$$barcode === void 0 ? void 0 : _value$$barcode.barcode) !== null && _value$0$barcode$barc !== void 0 ? _value$0$barcode$barc : '--') + " ")];
      }
    }, {
      key: "body_markup",
      fn: function (_ref11) {
        var value = _ref11.value;
        return [_vm._v(" " + _vm._s(value ? value + '%' : '--') + " ")];
      }
    }, {
      key: "body_nds",
      fn: function (_ref12) {
        var _item$taxRate$name, _item$taxRate;

        var item = _ref12.item;
        return [_vm._v(" " + _vm._s((_item$taxRate$name = (_item$taxRate = item.taxRate) === null || _item$taxRate === void 0 ? void 0 : _item$taxRate.name) !== null && _item$taxRate$name !== void 0 ? _item$taxRate$name : '--') + " ")];
      }
    }, {
      key: "body_country",
      fn: function (_ref13) {
        var _item$country$name, _item$country;

        var item = _ref13.item;
        return [_c('div', {
          staticClass: "table-left-text"
        }, [_vm._v(_vm._s((_item$country$name = (_item$country = item.country) === null || _item$country === void 0 ? void 0 : _item$country.name) !== null && _item$country$name !== void 0 ? _item$country$name : '--'))])];
      }
    }, {
      key: "body_supplier",
      fn: function (_ref14) {
        var _item$supplier$name2, _item$supplier2;

        var item = _ref14.item;
        return [_c('div', {
          staticClass: "table-left-text"
        }, [_vm._v(_vm._s((_item$supplier$name2 = (_item$supplier2 = item.supplier) === null || _item$supplier2 === void 0 ? void 0 : _item$supplier2.name) !== null && _item$supplier$name2 !== void 0 ? _item$supplier$name2 : '--'))])];
      }
    }, {
      key: "body_category",
      fn: function (_ref15) {
        var _item$category$name, _item$category;

        var item = _ref15.item;
        return [_c('div', {
          staticClass: "table-left-text"
        }, [_vm._v(_vm._s((_item$category$name = (_item$category = item.category) === null || _item$category === void 0 ? void 0 : _item$category.name) !== null && _item$category$name !== void 0 ? _item$category$name : '--'))])];
      }
    }], null, true)
  }, [_vm._l(_vm.storage_list, function (storage, index) {
    return [_c('template', {
      slot: 'children_' + storage.id
    }, [_c('span', {
      key: index,
      attrs: {
        "id": storage.id
      }
    }, [_vm._v(_vm._s(storage.name))]), _c('b-tooltip', {
      key: storage.id + index,
      attrs: {
        "target": storage.id
      }
    }, [_vm._v(" " + _vm._s(storage.name) + " ")])], 1)];
  })], 2), _c('table-navbar', {
    staticClass: "m-3",
    staticStyle: {
      "position": "absolute",
      "bottom": "0",
      "right": "0",
      "left": "0",
      "margin-left": "0 !important"
    },
    attrs: {
      "items": _vm.selected,
      "hide_print": "",
      "items_name": "product",
      "clear_selected": _vm.clearSelected
    },
    on: {
      "remove_items": _vm.checkIdsToDelete,
      "print_price_tags": _vm.printPriceTags,
      "product_move": _vm.productMove,
      "show_move": _vm.showMovement,
      "revaluate": _vm.createRevaluation,
      "copy": _vm.copyProduct,
      "merge_products": _vm.mergeProducts
    }
  })] : [_c('div', {
    staticClass: "center empty-block"
  }, [_vm._v(" Выберите группу товаров, создайте группу товаров или загрузите со своей кассы ")])]], 2), _c('modal-print-price-tags', {
    attrs: {
      "products": _vm.print_products,
      "loading": _vm.loading_print
    }
  }), _c('pagination', {
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "total": this.getPagination.products.total,
      "table_name": "products"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  }), _c('modal-select-group', {
    ref: "category-product-move",
    attrs: {
      "set_category": _vm.setCategory,
      "category": _vm.currentCategory
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }