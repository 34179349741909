var render = function render(){
  var _vm$productList, _vm$productList$, _vm$productList2, _vm$productList2$, _vm$productList3, _vm$productList3$, _vm$productList3$$sup, _vm$productList4, _vm$productList4$, _vm$productList5, _vm$productList6, _vm$productList6$, _vm$productList7, _vm$productList7$, _vm$productList8, _vm$productList8$, _vm$productList8$$sup, _vm$productList9, _vm$productList9$, _vm$productList10;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "merge-products-modal",
      "centered": "",
      "hide-footer": "",
      "size": "lg"
    },
    on: {
      "hide": _vm.hideModal,
      "show": _vm.openModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "title-block d-flex justify-content-between w-100"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Объединение товаров")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])], 1), _vm.showInfo ? _c('div', {
          staticClass: "info-container"
        }, [_c('div', {
          staticClass: "d-flex align-items-center info-content"
        }, [_c('img', {
          staticClass: "info-icon",
          attrs: {
            "src": "/img/icons/info-blue.svg",
            "alt": "info"
          }
        }), _c('p', [_vm._v(" Укажите главную и подчиняемую ей карточку товара. После объединения система заменит в документах подчинённую на главную карточку; перенесёт с неё штрихкоды и дополнительную информацию. Подчинённая карточка будет удалена. ")])]), _c('img', {
          staticClass: "close-help",
          attrs: {
            "src": "/img/icons/blue-close.svg",
            "alt": "close"
          },
          on: {
            "click": _vm.closeInfo
          }
        })]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "products-container"
  }, [_c('div', {
    staticClass: "products-block"
  }, [_c('div', {
    staticClass: "products-block__title"
  }, [_vm._v("Главная карточка")]), _c('div', {
    staticClass: "products-block__item-block"
  }, [_c('div', {
    staticClass: "products-block__item-name"
  }, [_vm._v(_vm._s((_vm$productList = _vm.productList) === null || _vm$productList === void 0 ? void 0 : (_vm$productList$ = _vm$productList[0]) === null || _vm$productList$ === void 0 ? void 0 : _vm$productList$.name))]), _c('div', {
    staticClass: "products-block__item-description"
  }, [_c('div', [_vm._v(_vm._s((_vm$productList2 = _vm.productList) === null || _vm$productList2 === void 0 ? void 0 : (_vm$productList2$ = _vm$productList2[0]) === null || _vm$productList2$ === void 0 ? void 0 : _vm$productList2$.articul))]), _c('div', [_vm._v(_vm._s((_vm$productList3 = _vm.productList) === null || _vm$productList3 === void 0 ? void 0 : (_vm$productList3$ = _vm$productList3[0]) === null || _vm$productList3$ === void 0 ? void 0 : (_vm$productList3$$sup = _vm$productList3$.supplier) === null || _vm$productList3$$sup === void 0 ? void 0 : _vm$productList3$$sup.name))])]), _c('div', {
    staticClass: "products-block__item-info"
  }, [_c('div', [_vm._v("Розничная цена: " + _vm._s((_vm$productList4 = _vm.productList) === null || _vm$productList4 === void 0 ? void 0 : (_vm$productList4$ = _vm$productList4[0]) === null || _vm$productList4$ === void 0 ? void 0 : _vm$productList4$.retailPrice))]), _c('div', {
    staticClass: "products-block__delimiter"
  }), _c('div', [_vm._v("Остаток:" + _vm._s(_vm.getTotalRemainders((_vm$productList5 = _vm.productList) === null || _vm$productList5 === void 0 ? void 0 : _vm$productList5[0])) + " шт")])])])]), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "cursor",
    attrs: {
      "title": "Поменять карточки местами",
      "src": "/img/icons/products/reverse_items.svg",
      "alt": ""
    },
    on: {
      "click": _vm.reverseProducts
    }
  }), _c('div', {
    staticClass: "products-block"
  }, [_c('div', {
    staticClass: "products-block__title"
  }, [_vm._v("Подчиняемая карточка")]), _c('div', {
    staticClass: "products-block__item-block"
  }, [_c('div', {
    staticClass: "products-block__item-name"
  }, [_vm._v(_vm._s((_vm$productList6 = _vm.productList) === null || _vm$productList6 === void 0 ? void 0 : (_vm$productList6$ = _vm$productList6[1]) === null || _vm$productList6$ === void 0 ? void 0 : _vm$productList6$.name))]), _c('div', {
    staticClass: "products-block__item-description"
  }, [_c('div', [_vm._v(_vm._s((_vm$productList7 = _vm.productList) === null || _vm$productList7 === void 0 ? void 0 : (_vm$productList7$ = _vm$productList7[1]) === null || _vm$productList7$ === void 0 ? void 0 : _vm$productList7$.articul))]), _c('div', [_vm._v(_vm._s((_vm$productList8 = _vm.productList) === null || _vm$productList8 === void 0 ? void 0 : (_vm$productList8$ = _vm$productList8[1]) === null || _vm$productList8$ === void 0 ? void 0 : (_vm$productList8$$sup = _vm$productList8$.supplier) === null || _vm$productList8$$sup === void 0 ? void 0 : _vm$productList8$$sup.name))])]), _c('div', {
    staticClass: "products-block__item-info"
  }, [_c('div', [_vm._v("Розничная цена: " + _vm._s((_vm$productList9 = _vm.productList) === null || _vm$productList9 === void 0 ? void 0 : (_vm$productList9$ = _vm$productList9[1]) === null || _vm$productList9$ === void 0 ? void 0 : _vm$productList9$.retailPrice))]), _c('div', {
    staticClass: "products-block__delimiter"
  }), _c('div', [_vm._v("Остаток: " + _vm._s(_vm.getTotalRemainders((_vm$productList10 = _vm.productList) === null || _vm$productList10 === void 0 ? void 0 : _vm$productList10[1])) + " шт")])])])])]), _c('hr'), _c('div', {
    staticClass: "footer-buttons"
  }, [_c('e-button', {
    staticClass: "mr-2",
    attrs: {
      "size": "m",
      "variant": "primary"
    },
    on: {
      "click": _vm.mergeProducts
    }
  }, [_vm._v(" Объединить ")]), _c('e-button', {
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Отмена")])], 1)]), _c('warning-merge-products-modal', {
    attrs: {
      "warning_text": _vm.warningText
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }