var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "products"
  }, [_c('div', {
    staticClass: "products__wrapper"
  }, [_c('products-menu', {
    attrs: {
      "menu_arr": _vm.menu_arr,
      "menu_is_loading": _vm.menu_is_loading
    },
    on: {
      "search_category": _vm.setSearchCategory,
      "clear_search_category": _vm.clearSearchCategory,
      "refetch": _vm.refetch
    }
  }), !_vm.menuIsVisible ? _c('a', {
    staticClass: "toggle-sidebar cursor",
    attrs: {
      "id": "show-groups"
    },
    on: {
      "click": function ($event) {
        return _vm.setMenuVisibleStatus(true);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/toggle-menu-arrow.svg",
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "products__item"
  }, [_c('b-tooltip', {
    attrs: {
      "target": "show-groups",
      "triggers": "hover",
      "delay": {
        show: 500,
        hide: 200
      },
      "boundary": "viewport",
      "placement": "topright",
      "offset": "-10"
    }
  }, [_vm._v(" Раскрыть группы товаров ")]), _c('products-table', {
    on: {
      "refetch": _vm.refetch
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }