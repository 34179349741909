var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', {
    attrs: {
      "variant": _vm.variant,
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.click_btn
    }
  }, [_vm.loading ? [_c('b-spinner', {
    staticStyle: {
      "height": "20px",
      "width": "20px"
    },
    attrs: {
      "variant": _vm.spinner_variant
    }
  })] : [_vm._v(" " + _vm._s(_vm.button_text) + " ")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }