<template>
  <b-button
    :variant="variant"
    :disabled="disabled"
    @click="click_btn"
  >
    <template v-if="loading">
      <b-spinner
        :variant="spinner_variant"
        style="height: 20px; width: 20px"
      />
    </template>

    <template v-else>
      {{ button_text }}
    </template>
  </b-button>
</template>

<script>
  export default {
    props: {
      variant: {
        type: String,
        default: ''
      },

      button_text: {
        type: String,
        default: ''
      },

      loading: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      spinner_variant: {
        type: String,
        default: 'light'
      }
    },

    methods: {
      click_btn() {
        this.$emit('click_btn')
      }
    }
  }
</script>

<style lang="scss" scoped></style>
