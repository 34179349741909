<template>
  <b-modal
    id="merge-products-modal"
    centered
    hide-footer
    size="lg"
    @hide="hideModal"
    @show="openModal"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="title-block d-flex justify-content-between w-100">
          <h5 class="title">Объединение товаров</h5>
          <b-button
            class="close"
            @click="hideModal"
          >
            <img
              src="/img/icons/modal_close.svg"
              alt=""
            />
          </b-button>
        </div>
        <div
          v-if="showInfo"
          class="info-container"
        >
          <div class="d-flex align-items-center info-content">
            <img
              class="info-icon"
              src="/img/icons/info-blue.svg"
              alt="info"
            />
            <p>
              Укажите главную и подчиняемую ей карточку товара. После объединения система заменит в документах
              подчинённую на главную карточку; перенесёт с неё штрихкоды и дополнительную информацию. Подчинённая
              карточка будет удалена.
            </p>
          </div>
          <img
            class="close-help"
            src="/img/icons/blue-close.svg"
            alt="close"
            @click="closeInfo"
          />
        </div>
      </div>
    </template>
    <div class="modal-body">
      <div class="products-container">
        <div class="products-block">
          <div class="products-block__title">Главная карточка</div>
          <div class="products-block__item-block">
            <div class="products-block__item-name">{{ productList?.[0]?.name }}</div>
            <div class="products-block__item-description">
              <div>{{ productList?.[0]?.articul }}</div>
              <div>{{ productList?.[0]?.supplier?.name }}</div>
            </div>
            <div class="products-block__item-info">
              <div>Розничная цена: {{ productList?.[0]?.retailPrice }}</div>
              <div class="products-block__delimiter"></div>
              <div>Остаток:{{ getTotalRemainders(productList?.[0]) }} шт</div>
            </div>
          </div>
        </div>

        <img
          v-b-tooltip.hover.top
          title="Поменять карточки местами"
          class="cursor"
          src="/img/icons/products/reverse_items.svg"
          alt=""
          @click="reverseProducts"
        />

        <div class="products-block">
          <div class="products-block__title">Подчиняемая карточка</div>
          <div class="products-block__item-block">
            <div class="products-block__item-name">{{ productList?.[1]?.name }}</div>
            <div class="products-block__item-description">
              <div>{{ productList?.[1]?.articul }}</div>
              <div>{{ productList?.[1]?.supplier?.name }}</div>
            </div>
            <div class="products-block__item-info">
              <div>Розничная цена: {{ productList?.[1]?.retailPrice }}</div>
              <div class="products-block__delimiter"></div>
              <div>Остаток: {{ getTotalRemainders(productList?.[1]) }} шт</div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="footer-buttons">
        <e-button
          size="m"
          class="mr-2"
          variant="primary"
          @click="mergeProducts"
        >
          Объединить
        </e-button>

        <e-button
          size="m"
          variant="outline-primary"
          @click="hideModal"
          >Отмена</e-button
        >
      </div>
    </div>
    <warning-merge-products-modal :warning_text="warningText" />
  </b-modal>
</template>

<script>
  import WarningMergeProductsModal from './WarningMergeProductsModal.vue'
  export default {
    name: 'MergeProductsModal',

    components: {
      WarningMergeProductsModal
    },

    props: {
      products: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        showInfo: true,
        productList: [],
        warningText: ''
      }
    },
    beforeMount() {
      if (localStorage.getItem('isInfoMergeProductsModal')) {
        this.showInfo = false
      }
    },
    methods: {
      openModal() {
        this.productList = JSON.parse(JSON.stringify(this.products))
      },

      reverseProducts() {
        this.productList.reverse()
      },

      hideModal() {
        this.$bvModal.hide('merge-products-modal')
        this.productList = []
        this.warningText = ''
      },
      closeInfo() {
        localStorage.setItem('isInfoMergeProductsModal', 'true')
        this.showInfo = false
      },

      mergeProducts() {
        this.$bvModal
          .msgBoxConfirm('Вы действительно хотите объединить выбранные товары? Подчинённая карточка будет удалена.', {
            title: 'Подтверждение объединения',
            size: 'ml',
            buttonSize: 'sm',
            okVariant: 'primary',
            cancelVariant: 'outline-primary',
            okTitle: 'Продолжить',
            footerClass: 'flex-row-reverse justify-content-end p-3',
            cancelTitle: 'Отмена',
            centered: true
          })
          .then(async (value) => {
            if (value) {
              this.setMergeProducts()
            }
          })
      },

      async setMergeProducts() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/MergeProducts.gql'),
            variables: {
              master: this.productList?.[0]?.id,
              slave: this.productList?.[1]?.id
            }
          })
          this.$noty.show(`Карточки объединены`)
          this.$emit('merge_products')
          this.hideModal()
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
          err?.graphQLErrors?.forEach((el) => {
            if (el?.message === 'RECURSIVE_COMPOUND') {
              this.warningText = el?.extensions?.ru
              this.$bvModal.show('warning-merge-products-modal')
            }
          })
        }
      },

      getTotalRemainders(item) {
        let total = 0
        if (item?.productType?.id === '52a4eab0-55c2-49f1-a152-f02cbc61e0b8') return '-'
        if (!item?.remainders) return 0

        item?.remainders?.forEach((i) => {
          total = total + i.value
        })

        return this.formatRemainderValue(total)
      },

      formatRemainderValue(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 3,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep .modal-footer button {
    width: fit-content;
  }
  .header-container {
    width: 100%;
    .title-block {
      margin-bottom: 16px;
      .title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
    .info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      width: 100%;
      background: #eef9ff;
      border-left: 3px solid #99daff;
      border-radius: 2px;
      .close-help {
        cursor: pointer;
      }
      .info-icon {
        align-self: flex-start;
      }
      p {
        margin: 0 0 0 13px;
        color: var(--Text-primary---gray700, #313131);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  .products-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    margin-bottom: 25px;
  }

  .products-block {
    flex: 1;

    &__title {
      color: var(--Text-primary---gray700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 5px;
    }

    &__item-block {
      border: 1px solid var(--Gray-gray150, #e1e1e1);
      padding: 10px;
      border-radius: 4px;
    }

    &__item-name {
      color: var(--Text-primary---gray700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    &__item-description {
      margin-top: 5px;
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--Text-secondary---gray400, #888);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__item-info {
      border-radius: 4px;
      background: #f8f8f9;
      padding: 5px 10px;

      margin-top: 7px;
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--Text-primary---gray700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__delimiter {
      width: 1px;
      height: 10px;
      border-radius: 14px;
      background: var(--Text-placeholder---gray300, #bcbcbc);
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .footer-buttons {
    display: flex;
    width: 100%;
    padding-bottom: 22px;
  }
</style>
