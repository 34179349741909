<template>
  <b-modal
    id="section-modal"
    centered
    @hide="clearModal"
    @shown="getCategory"
  >
    <template #modal-header>
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; flex-direction: row; overflow: hidden">
          <h5 class="mr-2">Добавление секций</h5>
        </div>
      </div>
    </template>
    <div class="markup-info-div">
      <div
        style="display: flex; flex-direction: row; align-items: flex-start"
        class="mb-2"
      >
        <img
          src="/img/icons/info_blue.svg"
          class="mr-2"
        />
        <span style="color: black; font-weight: 500"
          >Эта фукция разделит ваш чек на секции (несколько чеков). Это применяется в случае использования нескольких
          систем налогооблажения или разделения магазина по отделам</span
        >
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <div
        style="display: flex; flex-direction: row; align-items: center"
        class="mr-3"
      >
        <b-form-group>
          <label>Значение секции </label>
          <i-select
            v-model="section"
            filterable
            :allow-create="false"
            @on-create="createSection"
            @on-open-change="setEdit(-1)"
          >
            <i-option
              v-for="item in sections"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
              <!--              <div-->
              <!--                style="-->
              <!--                  display: flex;-->
              <!--                  flex-direction: row;-->
              <!--                  justify-content: space-between;-->
              <!--                "-->
              <!--              >-->
              <!--                <b-input-->
              <!--                  v-if="edited === index"-->
              <!--                  v-model="edited_name"-->
              <!--                  id="nameinp"-->
              <!--                  ref="nameinp"-->
              <!--                  class="w-75"-->
              <!--                  @click.stop="setFocus"-->
              <!--                  @keyup.enter="editName"-->
              <!--                />-->
              <!--                <span v-else>-->
              <!--                  {{ item.name }}-->
              <!--                </span>-->
              <!--                <div style="align-items: center; display: flex">-->
              <!--                  <img-->
              <!--                    @click.stop="setEdit(index)"-->
              <!--                    src="/img/icons/pencil_edit.svg"-->
              <!--                    height="14"-->
              <!--                    width="14"-->
              <!--                  />-->
              <!--                  <img-->
              <!--                    @click.stop="deleteItem(index)"-->
              <!--                    src="/img/icons/trash.svg"-->
              <!--                    class="ml-2"-->
              <!--                    height="14"-->
              <!--                    width="14"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </div>-->
            </i-option>
          </i-select>
        </b-form-group>
      </div>
    </div>
    <template #modal-footer="{ hide }">
      <div class="d-flex">
        <b-button
          class="button-pad btn-primary"
          @click="submitPriceUp"
        >
          Применить
        </b-button>
        <b-button
          variant="outline-primary"
          class="button-pad"
          @click="hide()"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { CategoryModel } from '@/models/category.model'
  import { mapGetters } from 'vuex'

  export default {
    name: 'SectionModal',

    props: {
      category: {
        type: CategoryModel,
        default: () => new CategoryModel()
      },
      sections: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        section: '',
        edited: -1,
        edited_name: '',
        found_category: new CategoryModel()
      }
    },
    methods: {
      async submitPriceUp() {
        await this.$apollo.mutate({
          mutation: require('../gql/CreateProps.graphql'),
          variables: {
            input: {
              branch: this.currentBranch.id,
              category: this.found_category.id,
              name: 'section',
              value: this.section,
              id: this.found_category?.props?.find((el) => el.name === 'section')?.id ?? null
            }
          }
        })
        this.$bvModal.hide('section-modal')
      },
      mounted() {},
      clearModal() {},
      async getCategory() {
        const { data } = await this.$apollo.query({
          query: require('../gql/getCategory.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            branch: this.currentBranch.id,
            id: this.category.id
          }
        })
        if (data) this.found_category = new CategoryModel(data.Category)
        this.section = data?.Category?.props?.[0]?.value ?? ''
      },
      async createSection(it) {
        await this.$apollo.mutate({
          mutation: require('../gql/CreateProps.graphql'),
          variables: {
            input: {
              branch: this.currentBranch.id,
              category: this.category.id,
              name: 'section',
              value: it
            }
          }
        })
      },
      async deleteItem(index) {
        await this.$apollo.mutate({
          mutation: require('../gql/RemoveProps.graphql'),
          variables: {
            input: {
              id: this.sections[index].id
            }
          }
        })
      },
      async editName() {
        await this.$apollo.mutate({
          mutation: require('../gql/UpdateProps.graphql'),
          variables: {
            input: {}
          }
        })
        this.edited = -1
      },
      setEdit(index) {
        this.edited = index
        if (index !== -1) {
          this.edited_name = this.sections[index].name
          setTimeout(() => {
            const el = document.getElementById('nameinp')
            el?.select()
          }, 100)
        }
      },
      setFocus() {
        setTimeout(() => {
          const el = document.getElementById('nameinp')
          el?.focus()
        }, 100)
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .modal-footer {
      padding: 12px 22px !important;
      border-top: 1px solid #e1e1e1;
    }
  }

  #section-modal {
    .modal-footer {
      padding: 12px 22px !important;
      border-top: 1px solid #e1e1e1;
    }
  }
  .markup-info-div {
    background: #eef9ff;
    padding: 16px 18px;
    border-left: 2px solid #99daff;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  .button-pad {
    padding: 6px 12px !important;
  }
</style>
