<template>
  <div
    v-if="menuIsVisible"
    class="products__menu"
  >
    <comparison-modal />
    <div class="products__menu-title d-flex align-items-center">
      <div
        v-if="!isSearchCategory"
        class="menu-title"
      >
        Группы товаров
      </div>

      <transition name="input-category">
        <div
          v-if="isSearchCategory"
          class="menu-block-category-search"
        >
          <img
            class="menu-block-category-search__img"
            src="/img/icons/products/search_category.svg"
            alt="search"
          />
          <b-form-input
            v-model="searchCategory"
            class="menu-block-category-search__input"
            type="text"
            placeholder="Поиск..."
            @change="setSearchCategory"
          />
          <img
            class="menu-block-category-search__close-input"
            src="/img/icons/products/close.svg"
            alt="clode"
            @click="closeSearchCategory"
          />
        </div>
      </transition>
      <div class="products__menu-title-left-block">
        <b-button
          v-if="!isSearchCategory"
          class="cursor mr-2"
          @click="showSearchCategory"
        >
          <img
            src="/img/icons/products/search_category.svg"
            alt="search"
          />
        </b-button>
        <b-button
          id="tooltip-add-group"
          class="cursor menu-btn-show-add-group"
          @click="showAddGroup"
        >
          <img
            src="/img/icons/products/product_group_plus.svg"
            alt="Добавить группу"
          />
        </b-button>
        <b-tooltip
          target="tooltip-add-group"
          triggers="hover"
          :delay="{ show: 500, hide: 200 }"
          boundary="viewport"
          placement="topleft"
        >
          Добавить группу товаров
        </b-tooltip>
      </div>
    </div>
    <template v-if="block_add_group">
      <div class="p-2">
        <add-group
          @refetchData="refetchData"
          @closeAddGroup="closeAddGroup"
          @groupAddedSuccessfully="groupAddedSuccessfully"
        />
      </div>
    </template>
    <div
      v-if="menu_is_loading"
      class="products__menu-no-goups center"
    >
      <b-spinner variant="primary" />
    </div>
    <template v-if="menu_arr && menu_arr.length > 0 && !menu_is_loading">
      <!--<div v-if="child_is_load" class="menu-is-loading center p-3">
        <b-spinner variant="primary" />
      </div>-->
      <div
        v-if="!connectionStatus"
        class="no-connection d-flex align-items-center w-100 justify-content-between"
      >
        <img
          src="/img/icons/attention-white.svg"
          alt="error"
        />
        <p class="m-0">Свяжите группы с базой франшизы</p>
        <e-button
          size="m"
          @click="$bvModal.show('comparison-modal')"
        >
          <img
            src="/img/icons/left-arrow.svg"
            alt="error"
          />
        </e-button>
      </div>
      <div class="b-search-result-products">
        <menu-item
          ref="menu-item"
          :close_add_group="closeAddGroup"
          :items="arr_menu"
          :open_group="openGroup"
          :update_menu="groupAddedSuccessfully"
          :modal_remove="openModalRemove"
          :current_ids="current_ids"
          :sections="sections"
          :current_group="current_group_id"
          @refetch-category="refetchData"
          @close_add_form="closeAddGroup"
          @set-group-markup="setGroupMarkup"
          @add-section="addSection"
        />
      </div>
    </template>
    <b-button
      class="toggle-sidebar"
      @click="hideProductMenu"
    >
      <img
        src="/img/icons/toggle-menu-arrow.svg"
        alt=""
      />
    </b-button>
    <b-modal
      id="modal-remove-group"
      hide-footer
      centered
      title="Удалить группу"
    >
      <div class="center">Вы действительно хотите удалить группу {{ group.name }} ?</div>
      <div class="modal-footer mt-4">
        <b-button
          class="center"
          variant="outline-primary"
          @click="hideModalRemove"
        >
          Отмена
        </b-button>
        <elm-button
          class="center"
          variant="primary"
          button_text="Удалить"
          :loading="remove_group_is_loading"
          @click_btn="removeGroup"
        >
          Удалить
        </elm-button>
      </div>
    </b-modal>
    <group-markup-modal :category="markup_category" />
    <section-modal
      :category="selectedCategory"
      :sections="sections"
    />
  </div>
</template>

<script>
  import * as R from 'ramda'
  import { mapActions, mapGetters } from 'vuex'
  import elmButton from '@shared/components/elements/Button.vue'
  import AddGroup from './menu/AddGroup.vue'
  import MenuItem from './menu/MenuItem.vue'
  import SectionModal from '@/views/products/modal/SectionModal'
  import { CategoryModel } from '@/models/category.model'
  import GroupMarkupModal from '@/views/products/components/GroupMarkupModal'
  import ComparisonModal from '@/views/products/modal/ComparisonModal'

  export default {
    components: {
      ComparisonModal,
      // MenuItem: () => import('./menu/MenuItem.vue'),
      GroupMarkupModal,
      AddGroup,
      SectionModal,
      MenuItem,
      elmButton
    },
    apollo: {
      CategoryRecomendation: {
        query: require('../gql/CategoryRecomendation.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          if (data?.CategoryRecomendation) {
            this.connectionStatus = data?.CategoryRecomendation?.status
          }
        },
        skip() {
          return !this.isFranchise
        }
      },
      EvoSections: {
        query: require('../gql/EvoSections.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.sections = data?.EvoSections ?? []
        }
      }
    },

    props: {
      menu_arr: { type: Array, default: () => [] },
      menu_is_loading: Boolean
    },

    data: () => ({
      isSearchCategory: false,
      searchCategory: '',
      connectionStatus: true,
      arr_menu: [],
      modal_show: false,
      block_add_group: false,
      child_is_load: false,
      sections: [],
      menu_group: {
        name: ''
      },
      current_group_id: '',
      group: {
        name: '',
        id: ''
      },
      remove_group_is_loading: false,
      markup_category: new CategoryModel(),
      isCreatedNewGroup: false,
      selectedCategory: new CategoryModel()
    }),

    watch: {
      menu_arr(newVal) {
        this.arr_menu = newVal

        if (this.isCreatedNewGroup) {
          const lastItem = this.menu_arr[this.menu_arr.length - 1]
          this.$nextTick(() => this.$refs['menu-item'].scrollToItem(lastItem.id))
          this.isCreatedNewGroup = false
        }
      },
      current_category(newVal, oldVal) {
        if (newVal.id != null && newVal?.id === oldVal?.id) return
        // this.current_group_id = this.current_category.id
        if (newVal.from_search) this.openGroup(this.current_category.id)
        // this.$apollo.queries.ReadCategoryByPath.refetch()
      },
      currentCategoryId() {
        if (this.getPagination.products?.scrollTo) return
        this.setPagination({
          ...this.getPagination,
          products: {
            skip: 0,
            take: 20,
            current_page: 1
          }
        })
      }
    },

    computed: {
      ...mapGetters({
        menuIsVisible: 'products/getMenuVisibleStatus',
        current_category_path: 'products/getCurrentCategoryPath',
        current_category: 'products/getCurrentCategory',
        currentCategoryId: 'products/getCurrentCategoryMenuId',
        current_ids: 'products/getCurrentIds',
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination',
        getBranchList: 'settings/getBranchList'
      }),
      isFranchise() {
        return !!this.getBranchList.find((obj) => obj.id === this.currentBranch.id)?.franchise
      }
    },

    created() {
      this.$emit('refetch')
    },

    mounted() {},

    methods: {
      ...mapActions({
        setMenuVisibleStatus: 'products/setMenuVisibleStatus',
        setCurrentCategoryPath: 'products/setCurrentCategoryPath',
        setCurrentsIds: 'products/setCurrentIds',
        setCurrentCategory: 'products/setCurrentCategory',
        setAddgroupVisibleStatus: 'products/setAddgroupVisibleStatus',
        setCurrentCategoryMenuId: 'products/setCurrentCategoryMenuId',
        setSearchStr: 'products/setSearchStr',
        setPlaceholder: 'products/setPlaceholder',
        setPagination: 'settings/set_pagination'
      }),

      setSearchCategory() {
        this.$emit('search_category', this.searchCategory)
      },

      showSearchCategory() {
        this.isSearchCategory = true
      },

      closeSearchCategory() {
        this.isSearchCategory = false
        this.searchCategory = ''
        this.$emit('clear_search_category')
      },

      modal_add_group() {
        this.$refs.modal_add_group.show()
      },

      addSection(item) {
        this.selectedCategory = new CategoryModel(item)
        this.$bvModal.show('section-modal')
      },

      closeAddGroup() {
        this.block_add_group = false
      },

      showAddGroup() {
        this.block_add_group = true
        this.setAddgroupVisibleStatus(true)
      },

      groupAddedSuccessfully(item) {
        // this.current_group_id = this.current_category.id
        this.isCreatedNewGroup = true
        this.closeAddGroup()
        if (item) {
          const lastItem = this.menu_arr[this.menu_arr.length - 1]
          this.selectCategory(item, lastItem.int_id + 1)
          this.$emit('refetch')
        } else {
          this.openGroup(this.current_category)
          this.$emit('refetch')
        }

        // this.$apollo.queries.Category.refetch()
      },

      selectCategory(item, number, index, value, clickCheck) {
        this.setSearchStr('')
        this.setPlaceholder('Поиск товаров по названию, коду или постав...')
        const category = {
          ...item,
          child_groups: null,
          parent_groups: {
            path: item.path?.map((obj, i, arr) => {
              if (i === arr.length - 1) {
                return
              }
              return obj
            })
          },
          number
        }

        this.setCurrentCategoryMenuId(item.id)
        this.$emit('select_group', {
          category,
          value,
          clickCheck
        })
        if (this.is_select_group) return this.$emit('selected_group', category)
        if (!this.is_select_group) {
          this.setCurrentCategory(category)
        }
      },

      refetchData() {
        this.$emit('refetch')
      },

      recursionMenu(items, childs) {
        const new_items = R.clone(items)

        return new_items?.map((item) => {
          if (item.id === childs.id) {
            item.child_groups = childs.child_groups?.map((child) => {
              if (child.child_groups) child.child_groups = null

              return child
            })
          } else if (item.child_groups) {
            item.child_groups = this.recursionMenu(item.child_groups, childs)
          }

          return item
        })
      },

      getItemsIds(items, curId = null) {
        let result = []

        if (items?.id) {
          result.push(items.id)
          if (items.id !== curId && items?.child_groups?.length) {
            items.child_groups.forEach((t) => {
              result = [...result, ...this.getItemsIds(t)]
            })
          }
        }
        return result
      },

      getCurrentCategoriesPath(path, items) {
        let result = path
        if (!result) {
          result = items
        } else if (result?.child_groups?.length) {
          result.child_groups.forEach((t, index) => {
            if (t?.id === items?.id) {
              result.child_groups[index] = items
            } else {
              const chGroups = this.getCurrentCategoriesPath(t, items)
              if (chGroups?.child_groups === null) {
                delete chGroups.child_groups
              }
              result.child_groups[index] = chGroups
            }
          })
        }
        return result
      },

      addChild(items) {
        this.arr_menu = this.recursionMenu(this.menu_arr, items)

        if (this.getItemsIds(this.current_category_path).includes(items.id)) {
          this.setCurrentCategoryPath(this.getCurrentCategoriesPath(this.current_category_path, items))
        } else {
          this.setCurrentCategoryPath(items)
        }

        this.toggleChildLoading()
      },

      async updateCategories(categories) {
        return categories
      },

      openGroup(item) {
        if (JSON.stringify(item?.path) == JSON.stringify(this.current_ids) && this.isCreatedNewGroup) {
          this.setCurrentCategoryMenuId(item.id)
          this.setCurrentsIds(item?.path)
          this.setCurrentCategoryPath(item?.path)
          this.isCreatedNewGroup = false

          this.$nextTick(() => {
            const el = document.getElementById(`item_${item.id}`).querySelectorAll('ul')[1].querySelectorAll('li')
            const lastItem = el[el.length - 1]
            lastItem.scrollIntoView({ block: 'center', behavior: 'smooth' })
          })
          this.$emit('refetch')
          return
        }

        if (this.isCreatedNewGroup) {
          this.setCurrentCategoryMenuId(item.id)
          this.setCurrentsIds(item?.path)
          this.setCurrentCategoryPath(item?.path)
          this.isCreatedNewGroup = false

          this.$nextTick(() => {
            const currentCategory = document.getElementById(`item_${item.id}`)
            const el_Ul = currentCategory.querySelectorAll('ul')
            if (el_Ul.length < 2) {
              currentCategory.scrollIntoView({ behavior: 'smooth' })
            } else {
              const li_elements = el_Ul[1].querySelectorAll('li')
              const lastItem = li_elements[li_elements.length - 1]
              lastItem.scrollIntoView({ block: 'center', behavior: 'smooth' })
            }
          })
          this.$emit('refetch')
          return
        }

        if (JSON.stringify(item?.path) == JSON.stringify(this.current_ids)) {
          this.isCreatedNewGroup = false
          this.setCurrentsIds((item?.path ?? []).slice(0, -1))
          return
        }
        if (item?.path?.every((el) => el === item.id) && this.current_ids.includes(item.id)) {
          this.setCurrentsIds([])
          return
        }

        this.setCurrentCategoryMenuId(item.id)
        this.setCurrentsIds(item?.path)
        this.setCurrentCategoryPath(item?.path)
        this.isCreatedNewGroup = false
        this.$emit('refetch')
      },

      toggleChildLoading() {
        this.child_is_load = !this.child_is_load
      },

      hideProductMenu() {
        this.setMenuVisibleStatus(false)
      },

      openModalRemove(group) {
        this.group = group
        this.$bvModal.show('modal-remove-group')
      },

      hideModalRemove() {
        this.$bvModal.hide('modal-remove-group')
      },

      removeGroup() {
        this.remove_group_is_loading = true

        this.$apollo
          .mutate({
            mutation: require('../gql/removeCategory.gql'),
            variables: {
              id: this.group.id
            }
          })
          .then(() => {
            this.$noty.show(`Группа "${this.group.name}" удалена`)
            this.removeItemMenu(this.menu_arr, this.group.id)
            this.setCurrentCategory(this.menu_arr[0])
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При удалении группы "${this.group.name}" что-то пошло не так. Попробуйте еще раз`)
          })
          .finally(() => {
            this.remove_group_is_loading = false
            this.$bvModal.hide('modal-remove-group')
          })
      },

      removeItemMenu(items, id) {
        items.forEach((item, i) => {
          if (item.id === id) {
            items.splice(i, 1)
          } else if (item.child_groups) {
            this.removeItemMenu(item.child_groups, id)
          }
        })
      },
      async setGroupMarkup(category) {
        const { data } = await this.$apollo.query({
          query: require('../gql/getCategory.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            id: category.id,
            branch: this.currentBranch.id
          }
        })
        this.markup_category = new CategoryModel(data.Category)
        this.$bvModal.show('group-markup-modal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input-category-enter-active {
    transition: all 0.3s ease-out;
  }

  .input-category-leave-active {
    transition: all 0.3s ease-out;
  }

  .input-category-enter {
    transform: translateX(20px);
    opacity: 0;
  }
  .input-category-enter-to {
    transform: translateX(-1px);
    opacity: 1;
  }

  .input-category-leave {
    transform: translateX(20px);
    opacity: 1;
  }
  .input-category-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }

  .products__menu-title-left-block {
    display: flex;
    align-items: center;

    :deep button {
      background: white !important;
      border: 1px solid #cbcaca !important;
    }
  }

  .menu-title {
    text-wrap: nowrap;
  }

  .menu-block-category-search {
    width: 100%;
    margin-right: 7px;
    position: relative;

    &__input {
      padding-left: 30px;
      padding-right: 30px;
    }

    &__close-input {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    &__img {
      position: absolute;
      top: 8px;
      left: 10px;
    }
  }

  .no-connection {
    padding: 13px;
    background: #e53835;
    p {
      color: #fff;
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
    button {
      background-color: #d0322f;
    }
  }
  .products__menu {
    display: flex;
    flex-direction: column;
    position: relative;

    &-title {
      justify-content: space-between;

      button {
        background: #efefef;
        width: 32px;
        height: 32px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease !important;
        transform: scale(1);

        img {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: #ececec !important;
        }

        &:active {
          transform: scale(0.98);
          background-color: #dadada !important;
          border-color: #d3d3d3 !important;
        }
      }
    }

    &-no-goups {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;
      color: #cacaca;
    }

    .b-search-result-products {
      overflow-y: auto;
      padding-top: 15px;
      padding-right: 0;
      padding-left: 0;
      height: 100%;

      & > .menu-item {
        max-width: calc(100% - 15px);

        & > li {
          width: calc(100% - 15px) !important;
        }
      }
    }

    .menu-is-loading {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.7);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .toggle-sidebar {
      width: 22px;
      height: 22px;
      position: absolute;
      right: -10px;
      bottom: 33px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: 3px;
      z-index: 11;
      padding: 0;
      transition: 0.1s;

      img {
        margin: 0 auto;
      }
    }
  }
</style>
