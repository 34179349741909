var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "section-modal",
      "centered": ""
    },
    on: {
      "hide": _vm.clearModal,
      "shown": _vm.getCategory
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "overflow": "hidden"
          }
        }, [_c('h5', {
          staticClass: "mr-2"
        }, [_vm._v("Добавление секций")])])])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          staticClass: "button-pad btn-primary",
          on: {
            "click": _vm.submitPriceUp
          }
        }, [_vm._v(" Применить ")]), _c('b-button', {
          staticClass: "button-pad",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return hide();
            }
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "markup-info-div"
  }, [_c('div', {
    staticClass: "mb-2",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "flex-start"
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/info_blue.svg"
    }
  }), _c('span', {
    staticStyle: {
      "color": "black",
      "font-weight": "500"
    }
  }, [_vm._v("Эта фукция разделит ваш чек на секции (несколько чеков). Это применяется в случае использования нескольких систем налогооблажения или разделения магазина по отделам")])])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('div', {
    staticClass: "mr-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "center"
    }
  }, [_c('b-form-group', [_c('label', [_vm._v("Значение секции ")]), _c('i-select', {
    attrs: {
      "filterable": "",
      "allow-create": false
    },
    on: {
      "on-create": _vm.createSection,
      "on-open-change": function ($event) {
        return _vm.setEdit(-1);
      }
    },
    model: {
      value: _vm.section,
      callback: function ($$v) {
        _vm.section = $$v;
      },
      expression: "section"
    }
  }, _vm._l(_vm.sections, function (item) {
    return _c('i-option', {
      key: item.value,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }