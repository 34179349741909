var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'check-block': _vm.position === 'center'
    }
  }, [_c('div', {
    ref: "checkbox",
    staticClass: "custom_checkbox",
    class: {
      custom_checkbox__active: _vm.checked,
      ss: _vm.size === 's',
      sm: _vm.size === 'm',
      sl: _vm.size === 'l',
      sxl: _vm.size === 'xl',
      custom_checkbox__disabled: _vm.disabled
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.click.apply(null, arguments);
      }
    }
  }, [_vm.checked ? _c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "#ffffff",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9.40808 0.344266C9.71631 0.601554 9.71631 1.07516 9.40808 1.33245L4.19044 5.68775C3.97383 5.86856 3.6589 5.86856 3.44229 5.68775L0.59192 3.30847C0.28369 3.05119 0.28369 2.57758 0.59192 2.32029C0.83074 2.12094 1.17796 2.12094 1.41678 2.32029L3.81636 4.32329L8.58322 0.344266C8.82204 0.144917 9.16926 0.144917 9.40808 0.344266Z",
      "fill": "#ffffff"
    }
  })]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }