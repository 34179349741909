export const create_document_by_type = (type, branch, specs, storage) => {
  switch (type) {
    case 'incoming':
      return {
        variables: {
          specifications: specs,
          branch: branch,
          operation: 'income'
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.incoming.document.edit',
            params: { id: data.CreateIncomingDocument.id }
          }
        },
        mutation: require('../views/operational-processes/gql/CreateIncomingDocument.gql')
      }
    case 'write-off':
      return {
        variables: {
          specifications: specs,
          branch: branch,
          operation: 'write_off'
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.outgoing.document.edit',
            params: { id: data.CreateOutgoingDocument.id }
          }
        },
        mutation: require('../views/operational-processes/gql/CreateOutgoingDocument.gql')
      }
    case 'return':
      return {
        variables: {
          specifications: specs,
          branch: branch,
          operation: 'return'
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.outgoing.document.edit',
            params: { id: data.CreateOutgoingDocument.id }
          }
        },
        mutation: require('../views/operational-processes/gql/CreateOutgoingDocument.gql')
      }
    case 'movement':
      return {
        variables: {
          branch: branch,
          storage: storage,
          specifications: specs
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.movement.document.edit',
            params: { id: data.CreateMovementDocument.id }
          }
        },
        mutation: require('../views/operational-processes/gql/CreateMovementDocument.gql')
      }
    case 'inventory':
      return {
        variables: {
          branch: branch,
          storage: storage,
          specifications: specs
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.inventory.document.edit',
            params: { id: data.CreateInventarisationDocument.id }
          }
        },
        mutation: require('../views/operational-processes/pages/inventory/gql/CreateInventoryDocument.graphql')
      }
    case 'sale':
      return {
        variables: {
          branch: branch,
          specifications: specs
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.cashless.document.edit',
            params: { id: data.CreateOutgoingDocument.id }
          }
        },
        mutation: require('../views/operational-processes/gql/CreateOutgoingDocument.gql')
      }
    case 'invoice':
      return {
        variables: {
          branch: branch,
          specification: specs
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.invoice.document.edit',
            params: { id: data.createInvoice.id }
          }
        },
        mutation: require('../views/operational-processes/gql/createInvoice.gql')
      }
    case 'revaluation':
      return {
        variables: {
          branch: branch,
          specification: specs?.map((el) => {
            return {
              product: el.product
            }
          })
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.revaluation.document.edit',
            params: { id: data.CreateRevaluationHead.id }
          }
        },
        mutation: require('../views/operational-processes/gql/CreateRevaluationHead.gql')
      }
    case 'promo':
      return {
        variables: {
          input: {
            branch: branch,
            operation: 'promotion',
            specs: specs?.map((el) => {
              return {
                product: el.product
              }
            })
          }
        },
        getRoute: (data) => {
          return {
            name: 'operation-process.overhead.promo.document.edit',
            params: { id: data.CreatePromotionHead.id }
          }
        },
        mutation: require('../views/operational-processes/gql/CreatePromotionHead.graphql')
      }
  }
}
