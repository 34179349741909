<template>
  <section class="products">
    <!-- <h1>Товары</h1> -->
    <div class="products__wrapper">
      <products-menu
        :menu_arr="menu_arr"
        :menu_is_loading="menu_is_loading"
        @search_category="setSearchCategory"
        @clear_search_category="clearSearchCategory"
        @refetch="refetch"
      />
      <a
        v-if="!menuIsVisible"
        id="show-groups"
        class="toggle-sidebar cursor"
        @click="setMenuVisibleStatus(true)"
      >
        <img
          src="/img/icons/toggle-menu-arrow.svg"
          alt=""
        />
      </a>
      <div class="products__item">
        <b-tooltip
          target="show-groups"
          triggers="hover"
          :delay="{ show: 500, hide: 200 }"
          boundary="viewport"
          placement="topright"
          offset="-10"
        >
          Раскрыть группы товаров
        </b-tooltip>
        <products-table @refetch="refetch" />
      </div>
    </div>
  </section>
</template>

<script>
  import ProductsMenu from '../components/ProductsMenu.vue'
  import ProductsTable from '../components/ProductsTable.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    apollo: {
      ReadCategoryByPath: {
        query: require('../gql/getCategoryPath.graphql'),
        fetchPolicy: 'no-cache',
        skip() {
          return this.searchCategory
        },
        variables() {
          return {
            path: this.current_category_path?.filter((el) => el) ?? []
          }
        },
        async result({ data }) {
          if (data) {
            if (data?.ReadCategoryByPath?.[0] && !this.current_category?.id) {
              this.setCurrentCategory(data?.ReadCategoryByPath?.[0])
            }
            this.menu_arr = await this.updateCategories(data?.ReadCategoryByPath ?? [])
            this.menu_is_loading = false
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      SearchCategory: {
        query: require('../gql/SearchCategory.gql'),
        fetchPolicy: 'no-cache',
        skip() {
          return !this.searchCategory
        },
        variables() {
          return {
            search: this.searchCategory
          }
        },
        async result({ data }) {
          if (data) {
            if (data?.SearchCategory?.[0] && !this.current_category?.id) {
              this.setCurrentCategory(data?.SearchCategory?.[0])
            }
            this.menu_arr = await this.updateCategories(data?.SearchCategory ?? [])
            this.menu_is_loading = false
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    components: {
      ProductsMenu,
      ProductsTable
    },

    data() {
      return {
        menu_arr: [],
        searchCategory: '',
        menu_is_loading: true
      }
    },

    computed: {
      ...mapGetters({
        current_category_path: 'products/getCurrentCategoryPath',
        getCurrentCategoryMenuId: 'products/getCurrentCategoryMenuId',
        current_category: 'products/getCurrentCategory',
        menuIsVisible: 'products/getMenuVisibleStatus'
      })
    },

    beforeMount() {
      this.setBreadcrumbs({})
    },

    methods: {
      ...mapActions({
        setMenuVisibleStatus: 'products/setMenuVisibleStatus',
        setBreadcrumbs: 'breadcrumbs/set_current',
        setCurrentCategory: 'products/setCurrentCategory'
      }),

      setSearchCategory(text) {
        this.searchCategory = text
      },

      clearSearchCategory() {
        this.searchCategory = ''
      },

      refetch() {
        this.$apollo.queries.ReadCategoryByPath.refetch()
      },
      async updateCategories(categories) {
        return categories
      }
    }
  }
</script>

<style lang="scss" scoped>
  .products {
    height: 100%;
    max-height: 100%;
    flex-direction: column;
    display: flex;

    &__wrapper {
      flex-direction: row;
      flex-grow: 1;
      margin-top: 0;
      max-height: calc(100% - 60px);
      position: relative;
    }

    .card {
      height: 100%;
    }

    .toggle-sidebar {
      top: 21px;
      left: -11px;
      bottom: auto;
      right: auto;
      z-index: 1 !important;

      img {
        transform: rotate(0);
      }
    }
  }
</style>
